export default class PatientOfDoctor {
  constructor(patient) {
    this._shared_id = patient.shared_id;
    this._first_name = patient.first_name;
    this._last_name = patient.last_name;
  }

  isPatient() {
    return true;
  }

  isDoctor() {
    return false;
  }

  id() {
    return this._shared_id;
  }

  seeNotePath() {
    return '/patients/audio-note';
  }

  displayInAudioPlayer() {
    return `${this._first_name} ${this._last_name}`;
  }

  path() {
    return `/patients/${this._shared_id}`;
  }

}