import MaiaChip from "./MaiaChip";

export default function SummaryStateChip({summaryContent, lightMode = true}) {
  if (summaryContent) {
    return <MaiaChip label="Resumida" color="pink"
                     variant={lightMode ? "filled" : "outlined"}/>
  } else {
    return <MaiaChip label="Sin Resumen" color="violet"
                     variant={lightMode ? "outlined" : "filled"}/>
  }
}