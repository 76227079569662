import OptionField from "../OptionField";
import {ethnicGroups} from "../../../../app/Constants";


export default class EthnicGroupField extends OptionField {
  constructor(initialValue = undefined) {
    const options = ethnicGroups.map((option) => option.secondary);
    super(options, initialValue);
  }

  autocompleteConfig() {
    return {
      options: ethnicGroups,
      groupOptionsBy: (option) => option.main,
      getOptionLabel: (option) => option.secondary || "",
      isOptionEqualToValue: (option, value) => {
        return option.secondary === value;
      },
      getValue: (option) => option?.secondary
    }
  }
}
