import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import Typography from '@mui/material/Typography';
import {toast} from 'react-toastify';
import FilledTextButton from "./buttons/FilledTextButton";
import LineTextButton from "./buttons/LineTextButton";
import MaiaCircularProgress from "./loaders/MaiaCircularProgress";

export default function FileUploadBox({onCancel, onFileUploaded}) {
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const style = styles(theme);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: {
      'image/*': [],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'plain/text': ['.txt'],
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    onFileDialogOpen: () => {
      setFiles([]);
    },
    onDropRejected: () => toast.error('Archivo inválido')
  });

  const onSaveButtonClick = async () => {
    await setUploadingFile(true);
    const file = acceptedFiles[0];
    await onFileUploaded(file);
    setUploadingFile(false);
  }

  const imagePreview = () => {
    const file = files[0];
    if (!file || !file.type.startsWith('image/')) {
      return null;
    }

    return (
      <div key={file.title} style={style.thumbContainer}>
        <div style={style.thumbInnerContainer}>
          <img
            style={style.thumb}
            src={file.preview}
            alt="thumbnail"
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.white,
        padding: '2rem',
        gap: '0.5rem',
        borderRadius: '5px',
      }} {...getRootProps({className: 'dropzone'})} >
        {uploadingFile ?
          <>
            <MaiaCircularProgress/>
            <Typography>
              Cargando archivo
            </Typography>
          </>
          :
          <>
            <input {...getInputProps()} />
            <Box>
              <Typography>Selecciona o arrastra los archivos</Typography>
            </Box>
            <Box>
              {acceptedFiles.length > 0 && <Typography>{acceptedFiles[0].name}</Typography>}
            </Box>
            {imagePreview()}
          </>
        }
      </Box>
      <Box sx={style.buttonsContainer}>
        <LineTextButton label={"Cancelar"} onClick={onCancel} light={true} disabled={uploadingFile}/>
        <FilledTextButton label="Guardar" onClick={onSaveButtonClick} disabled={uploadingFile || acceptedFiles.length === 0}/>
      </Box>
    </>

  );
}

const styles = () => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  thumbContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    background: '#e2e2e2'
  },
  thumbInnerContainer: {
    display: 'flex',
    minWidth: 0,
    justifyContent: 'center',
    overflow: 'hidden'
  }
});