import {SuccessfulApiResponse} from "@eryxcoop/appyx-comm";


export default class GetPatientsResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        patients: [
          {
            "first_name": "John",
            "last_name": "Doe",
            "identification_number": "123458678",
            "birthdate": "1990-01-01",
            "biological_sex": "MALE",
            "sharing_record": {
              "is_sharing": false,
              "expires_at": null
            }
          }
        ]
      },
    }
  }

  patients() {
    return this.content().patients;
  }
}