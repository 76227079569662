import {observer} from 'mobx-react';
import FormFieldBase from './FormFieldBase';
import '@mdxeditor/editor/style.css';
import '../../assets/maia-markdown-editor.css';
import {Checkbox, FormControlLabel, useTheme} from "@mui/material";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function FormCheckboxesField({
                                label = undefined,
                                disabled = false,
                                field,
                              }) {
  const theme = useTheme();
  const style = styles(theme);

  return (
    <FormFieldBase label={label} field={field}>
      <Box style={style.mainContainer}>
        {
          field.options().map((mh) => {
            return (
              <Box key={mh.section} style={style.sectionContainer}>
                <Typography variant={'body1'} sx={{color: theme.text.primary}} fontWeight={'800'}>
                  {mh.section}
                </Typography>
                <Box>
                  {
                    mh.subsection.map((subsection) => {
                      return (
                        <FormControlLabel sx={{color: theme.text.primary}}
                                          key={subsection.code}
                                          label={subsection.name}
                                          control={
                                            <Checkbox
                                              sx={{color: theme.text.darkGrey}}
                                              checked={field.hasValue(subsection.code)}
                                              disabled={disabled}
                                              name={subsection.code}
                                              onChange={(e) => {
                                                const checked = e.target.checked;
                                                checked ? field.addValue(subsection.code) : field.removeValue(subsection.code);
                                              }}
                                            />
                                          }
                        />
                      )
                    })
                  }
                </Box>
              </Box>
            )
          })
        }
      </Box>
    </FormFieldBase>
  );
}

const styles = (theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default observer(FormCheckboxesField);