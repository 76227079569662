import { useEffect, useState } from 'react';
import { useAudioRecorder as useAudioVoiceRecorder } from 'react-audio-voice-recorder';
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';

class AudioRecorder {
  constructor({
                startRecording,
                stopRecording,
                togglePauseResume,
                recordingTime,
                mediaRecorder,
                isRecording,
                isPaused,
                recordingCanceled,
                setRecordingCanceled
              }) {
    this._stopRecording = stopRecording;

    this._startRecording = startRecording;
    this._isRecording = isRecording;
    this._recordingTime = recordingTime;
    this._isPaused = isPaused;
    this._togglePauseResume = togglePauseResume;
    this._mediaRecorder = mediaRecorder;

    this._recordingCanceled = recordingCanceled;
    this._setRecordingCanceled = setRecordingCanceled;

    makeAutoObservable(this);
  }

  stopRecording = async () => {
    this._stopRecording();

    this._isRecording = false;
  };

  startRecording = () => {
    this._startRecording();
    this._isRecording = true;
    this._setRecordingCanceled(false);
  };

  deleteRecording = () => {
    this._stopRecording();
    this._isRecording = false;
    this._setRecordingCanceled(true);
    toast.info('Nota de audio cancelada.');
  };

  reset() {
    this._setRecordingCanceled(false);
  }

  togglePauseResume = () => {
    this._isPaused = !this._isPaused;
    this._togglePauseResume();
  };

  get isRecording() {
    return this._isRecording;
  }

  get recordingTime() {
    return this._recordingTime;
  }

  get isPaused() {
    return this._isPaused;
  }

  get mediaRecorder() {
    return this._mediaRecorder;
  }

  get recordingCanceled() {
    return this._recordingCanceled;
  }
}

const useBrowserAudioRecorder = ({onRecordingStopped, onRecordingCanceled}) => {
  const [recordingCanceled, setRecordingCanceled] = useState(false);
  const audioVoiceRecorder = useAudioVoiceRecorder();
  const [audioRecorder, setAudioRecorder] = useState(null);


  useEffect(() => {
    const newAudioRecorder = new AudioRecorder({...audioVoiceRecorder, recordingCanceled, setRecordingCanceled});

    setAudioRecorder(newAudioRecorder);
  }, [audioVoiceRecorder.mediaRecorder, audioVoiceRecorder.isPaused, audioVoiceRecorder.recordingTime, audioVoiceRecorder.isRecording]);

  useEffect(() => {
    if (!audioVoiceRecorder.recordingBlob) {
      return;
    }

    if (audioRecorder.recordingCanceled) {
      onRecordingCanceled?.();
      return
    }

    onRecordingStopped(audioVoiceRecorder.recordingBlob);

  }, [audioVoiceRecorder.recordingBlob]);

  return audioRecorder
};

export default useBrowserAudioRecorder;