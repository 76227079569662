import Box from "@mui/material/Box";
import * as React from "react";
import {useTheme} from "@mui/material";
import useFeature from "../hooks/useFeature";
import PatientProfileFeature from "../features/PatientProfileFeature";
import {useApplication} from "../providers/ApplicationProvider";
import {observer} from "mobx-react";
import FormTextField from "../components/fields/FormTextField";
import FormDateField from "../components/fields/FormDateField";
import FormSelectField from "../components/fields/FormSelectField";
import FilledTextButton from "../components/buttons/FilledTextButton";
import MaiaCircularProgress from "../components/loaders/MaiaCircularProgress";
import {ScreenTitleBar} from "../components/ScreenTitleBar";
import DashboardMainContainer from "../components/DashboardMainContainer";
import FormAutocompleteField from '../components/fields/FormAutocompleteField';
import FormTextAreaField from '../components/fields/FormTextAreaField';
import FormCheckboxesField from "../components/fields/FormCheckboxesField";

function PatientProfileScreen() {
  const theme = useTheme();
  const application = useApplication();
  const feature = useFeature(() => new PatientProfileFeature(application));
  const style = styles(theme);

  const profileForm = () => {
    return (
      <form style={style.formContainer}>
        <div style={style.rowContainer}>
          <FormTextField extraLabelStyle={style.label} label={'Nombre'}
                         field={feature.form.getFieldByName('firstName')}/>
        </div>
        <div style={style.rowContainer}>
          <FormTextField extraLabelStyle={style.label} label={'Apellido'}
                         field={feature.form.getFieldByName('lastName')}/>
        </div>
        <div style={style.rowContainer}>
          <FormTextField extraLabelStyle={style.label} label={'Email'} disabled={true}
                         field={feature.form.getFieldByName('email')}/>
        </div>
        <div style={style.rowContainer}>
          <FormTextField extraLabelStyle={style.label} label={'DNI'}
                         field={feature.form.getFieldByName('identificationNumber')}/>
        </div>
        <div style={style.rowContainer}>
          <FormDateField extraLabelStyle={style.label} label={'Fecha de Nacimiento'}
                         field={feature.form.getFieldByName('birthdate')}/>
          <FormSelectField extraLabelStyle={style.label} label={'Sexo'}
                           field={feature.form.getFieldByName('biologicalSex')}/>
        </div>
        <div style={style.rowContainer}>
          <FormAutocompleteField
            extraLabelStyle={style.label}
            label="Grupo Étnico"
            field={feature.form.getFieldByName('ethnicGroup')}
            config={feature.form.getFieldByName('ethnicGroup').autocompleteConfig()}
          />
        </div>
        <div style={style.rowContainer}>
          <FormTextAreaField field={feature.form.getFieldByName('familyHistory')} label="Antecedentes familiares"/>
        </div>
        <div style={style.rowContainer}>
          <FormCheckboxesField field={feature.form.getFieldByName('riskFactors')} label="Factores de riesgo"/>
        </div>
      </form>
    )
  }

  return feature && (
    <DashboardMainContainer>
      <ScreenTitleBar title={`Mi Perfil`}/>
      <Box sx={style.profileContainer}>
        {feature.isLoading ?
          <MaiaCircularProgress/>
          :
          <>
            {profileForm()}
            <Box sx={style.buttonsContainer}>
              <FilledTextButton label="Guardar" onClick={feature.updatePatientProfile}
                                disabled={!feature.form.isValid}/>
            </Box>
          </>
        }
      </Box>
    </DashboardMainContainer>
  );
}

const styles = (theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '1rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  label: {
    padding: '0.4rem 0.5rem',
    color: theme.colors.lightBlue,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    backgroundColor: theme.colors.lightBackground,
    borderRadius: '10px',
    marginTop: '1rem',
    gap: '1rem',
    width: '90%',
    maxWidth: '1200px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  }
});

export default observer(PatientProfileScreen);