import ListItem from '@mui/material/ListItem';
import MaiaIconButton from './buttons/MaiaIconButton';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import {ListItemAvatar, Tooltip, useTheme} from '@mui/material';
import MaiaAvatar from './MaiaAvatar';
import ListItemText from '@mui/material/ListItemText';
import {Link} from 'react-router-dom';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import {dayAndHoursDistanceFromMessage} from "../libs/utils";

export function PatientListItem({patient, canRecord, isRecording, startRecording, stopRecording}) {
  const theme = useTheme();
  const style = styles(theme);

  const fullName = `${patient.first_name} ${patient.last_name}`;

  const sharingMessage = (sharingRecordUntil) => {
    const sharedForMessage = sharingRecordUntil ? `${dayAndHoursDistanceFromMessage(new Date(sharingRecordUntil))}` : 'tiempo indefinido';
    return `Legajo siendo compartido por: ${sharedForMessage}`;
  }

  return (
    <ListItem
      alignItems="flex-start"
      secondaryAction={
        <Box style={style.boxContainer}>
          {patient.sharing_record.is_sharing &&
            <Tooltip title={sharingMessage(patient.sharing_record.expires_at)} placement={'left'}>
              <HistoryToggleOffIcon style={style.iconStyle}/>
            </Tooltip>
          }
          {
            isRecording ?
              <MaiaIconButton
                icon={StopCircleIcon}
                title={'Detener grabación'}
                onClick={stopRecording}
              />
              :
              <MaiaIconButton
                onClick={startRecording}
                disabled={!canRecord}
                title={'Iniciar grabación'}
                icon={KeyboardVoiceIcon}
              />
          }
        </Box>
      }
    >
      <ListItemAvatar>
        <MaiaAvatar fullName={fullName}/>
      </ListItemAvatar>
      <ListItemText
        primary={<Link to={`/patients/${patient.shared_id}`}>{fullName}</Link>}
        secondary={
          <React.Fragment>
            <Typography
              sx={{display: 'inline'}}
              component="span"
              variant="body2"
              color="text.primary"
            >
              DNI {patient.identification_number}
            </Typography>
            {` — Fecha de nacimiento: ${patient.birthdate || 'No indicada'}`}
          </React.Fragment>
        }
      />
    </ListItem>
  );
}

const styles = (theme) => ({
  boxContainer: {
    display: 'flex',
    gap: '8px'
  },
  iconStyle: {
    height: '1em',
    width: '1em',
    color: theme.palette.violet.main
  }
})