import Typography from "@mui/material/Typography";
import FilledTextButton from "../../buttons/FilledTextButton";
import Box from "@mui/material/Box";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import {Avatar} from "@mui/material";
import LineTextButton from "../../buttons/LineTextButton";
import * as React from "react";
import LoadingContainer from "../../loaders/LoadingContainer";

function ConfirmDoctorSelectedStep({form, getSharingTimeLabel, loading, onContinue, onBack}) {
  const style = styles();

  const timeSelected = form.getFieldValue("sharingTime");
  const doctorFullName = form.getFieldValue("doctorFullName");

  return (
    <>
      {loading && <LoadingContainer message={"Creando permiso..."}/>}
      {!loading &&
        <>
          <Box style={style.confirmInformationContainer}>
            <Avatar sx={{width: 80, height: 80}}>
              <LockPersonIcon sx={{width: 40, height: 40}}/>
            </Avatar>
            <Typography fontWeight={'bold'}>
              {doctorFullName} - <span>{getSharingTimeLabel(timeSelected)}</span>
            </Typography>
            <Typography style={style.warningBox}>
              El doctor podrá ver la nota seleccionada durante el tiempo seleccionado.
            </Typography>
          </Box>
          <Box style={style.buttonsContainer}>
            <LineTextButton label="Cancelar"
                            light={false}
                            style={{width: 'fit-content'}}
                            onClick={onBack}/>
            <FilledTextButton label="Confirmar"
                              style={{width: 'fit-content'}}
                              onClick={onContinue}/>
          </Box>
        </>
      }
    </>
  );
}

export default ConfirmDoctorSelectedStep;

const styles = () => ({
  confirmInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    textAlign: 'center'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.5rem',
  },
  warningBox: {
    border: '#ffb700',
    borderWidth: '2px',
    background: '#ffe9b8',
    padding: '8px 4px',
    borderStyle: 'solid'
  }
});