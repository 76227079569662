export default class MultipleOptionsValidator {
  constructor(options) {
    this._options = options;
  }

  isValid(value) {
    return value.every((option) => this._options.some((validOption) => validOption === option));
  }

  errorMessage() {
    return "Opción inválida";
  }
}
