import {observer} from 'mobx-react';
import PlayArrow from '@mui/icons-material/PlayArrow';
import {ListItemAvatar, useTheme} from "@mui/material";
import PlayButton from "./PlayButton";
import * as React from "react";
import ListEntryIcon from "../ListEntryIcon";

function AvatarPlayButton({audioOwner, audioScr, small=false}) {
  const theme = useTheme();

  return (
    <ListItemAvatar>
      <ListEntryIcon small={small}>
        <PlayButton
          audioScr={audioScr}
          audioOwner={audioOwner}
          size={small? 18 : 32}
          icon={PlayArrow}
          color={theme.colors.white}
        />
      </ListEntryIcon>
    </ListItemAvatar>
  )
}

export default observer(AvatarPlayButton);




