import MaiaChip from "./MaiaChip";

export default function TranscriptionStateChip({audioNote, lightMode = true}) {
  if (audioNote.hasFinished) {
    return <MaiaChip label="Transcripción Completada" color="primary"
                     variant={lightMode ? "outlined" : "filled"}/>
  } else if (audioNote.hasStarted) {
    return <MaiaChip label="Transcribiendo" color="pink" variant="filled"/>
  } else {
    return <MaiaChip label="Sin Transcripción" color="secondary"
                     variant={lightMode ? "outlined" : "filled"}/>
  }
}