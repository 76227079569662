import { getAuth, GoogleAuthProvider, signInWithPopup } from '@firebase/auth';
import { initializeApp } from 'firebase/app';

export default class FirebaseAuthenticationMethod {
  constructor (application) {
    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    this._auth = getAuth(app);
    this._application = application;
  }

  subscribeToAuthEvents () {
    return new Promise((resolve) => {
      this._auth.onAuthStateChanged(async (firebaseUser) => {
        await this._updateUserTokenFromFirebaseUser(firebaseUser);
        resolve();
      });
      this._auth.onIdTokenChanged(async (firebaseUser) => {
        await this._updateUserTokenFromFirebaseUser(firebaseUser);
      });
    });
  }

  async _updateUserTokenFromFirebaseUser (firebaseUser) {
    if (firebaseUser) {
      await this._updateUserToken(firebaseUser);
    }
  }

  async signIn () {
    const result = await signInWithPopup(this._auth, new GoogleAuthProvider());
    return await result.user.getIdToken();
  }

  async signOut () {
    await this._auth.signOut();
  }

  async refreshUserToken () {
    await this._updateUserToken(this._auth.currentUser, true);
  }

  async _updateUserToken (firebaseUser, forceRefresh = false) {
    const token = await this._getUserToken(firebaseUser, forceRefresh);
    this._application.updateUserToken(token);
  }

  async _getUserToken (firebaseUser, forceRefresh = false) {
    return await firebaseUser.getIdToken(forceRefresh);
  }
}