import { observer } from 'mobx-react';
import { useApplication } from '../providers/ApplicationProvider';
import { useTheme } from '@mui/material';
import useFeature from '../hooks/useFeature';
import { useLocation, useParams } from 'react-router-dom';
import { ScreenTitleBar } from '../components/ScreenTitleBar';
import * as React from 'react';
import TextNoteViewOrEdit from '../components/TextNoteViewOrEdit';
import Box from '@mui/material/Box';
import AudioNoteView from '../components/AudioNoteView';
import PatientOfDoctor from '../app/audioOwners/PatientOfDoctor';
import { ImageNoteView } from '../components/ImageNoteView';
import { SeeSharedEntryFeature } from '../features/SeeSharedEntryFeature';

function SeeSharedEntryScreen () {
  const application = useApplication();
  const location = useLocation();
  const theme = useTheme();
  const { patientId, entryId } = useParams();
  const feature = useFeature(() => new SeeSharedEntryFeature(application, patientId, entryId), []);
  const style = styles(theme);
  const patientInfo = location.state.patientInfo;

  return feature?.entry && (
    <section style={style.mainContainer}>
      <ScreenTitleBar title={feature.entry.title}/>
      <Box>
        {feature.entry.isAudio &&
          <AudioNoteView
            audioNote={feature.entry}
            audioOwner={new PatientOfDoctor(patientInfo)}
            audioNoteTranscription={feature.audioNoteTranscription}
            audioNoteTranscriptionDown={feature.audioNoteTranscriptionDown}
          />}
        {feature.entry.isText && <TextNoteViewOrEdit textNote={feature.entry} canBeEdited={false}/>}
        {feature.entry.isFile && <ImageNoteView imageNote={feature.entry}/>}
      </Box>
    </section>
  );
}

const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection:
      'column',
    gap:
      '2rem',
  }
});

export default observer(SeeSharedEntryScreen);