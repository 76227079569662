export default class TextMessage {
  constructor(text, files, wasSentByDoctor) {
    this._text = text;
    this._wasSentByDoctor = wasSentByDoctor;
  }

  static fromVirtualAssistant(text) {
    return new this(text, false);
  }

  static fromDoctor(text) {
    return new this(text, undefined, true);
  }

  get text() {
    return this._text;
  }

  get wasSentByDoctor() {
    return this._wasSentByDoctor;
  }
}