import Box from "@mui/material/Box";
import TagChip from "./TagChip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useState} from "react";
import useOutsideClick from "../hooks/useOutsideClick";

export default function TagsEditionBox({note, updateEntryTags, tags, editable = true}) {
  const [editTagsMode, setEditTagsMode] = useState(false);
  const [selectedTags, setSelectedTags] = useState(note.tags);
  const ref = useOutsideClick(() => setEditTagsMode(false), editTagsMode);
  const style = styles();

  const tagsBox = () => {
    return (
      <Box
        sx={style.tagsContainer} onClick={(e) => {
        if(editable) {
          e.preventDefault();
          setEditTagsMode(true);
        }
      }}>
        {selectedTags.length > 0 ?
          selectedTags.map(tag =>
            <TagChip key={tag.name} tag={tag}/>
          )
          :
          <Typography>
            No hay tags todavía
          </Typography>
        }
      </Box>
    )
  }

  const editTagsBox = () => {
    return (
      <Box sx={style.selectTagsContainer}
           onClick={(e) => {
             e.preventDefault()
           }}>
        <Box sx={style.selectedTagsContainer}>
          {
            selectedTags.map(tag =>
              <TagChip key={tag.name} tag={tag} onDelete={
                () => {
                  const tagsWithoutTag = selectedTags.filter(t => t.id !== tag.id);
                  setSelectedTags(tagsWithoutTag);
                  updateEntryTags(note, tagsWithoutTag);
                }
              }/>
            )
          }
        </Box>
        <Box sx={style.tagsOptionsContainer}>
          <Typography variant="body2" color="white">
            Seleccione las etiquetas
          </Typography>
          {
            tags.map(tag =>
              <Box key={'btn-container-' + tag.id} sx={style.buttonWrapper}>
                <button
                  key={'btn-' + tag.id}
                  style={style.selectTagsButton}
                  onClick={(e) => {
                    e.preventDefault();
                    const newTagsNames = selectedTags.map(t => t.name);
                    // add tag if it is not already there
                    if (!newTagsNames.includes(tag.name)) {
                      const newTags = [...selectedTags, tag];
                      setSelectedTags(newTags);
                      updateEntryTags(note, newTags);
                    }
                  }}>
                  <TagChip tag={tag}/>
                </button>
              </Box>
            )
          }
        </Box>
      </Box>
    )
  }

  return (
    <div ref={ref}>
      {editTagsMode ? editTagsBox() : tagsBox()}
    </div>
  );
}


const styles = () => ({
  tagsContainer: {
    display: 'flex',
    gap: '8px',
    padding: '0.4rem',
    width: 'fit-content',
  },
  selectedTagsContainer: {
    display: 'flex',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    gap: '8px',
    padding: '0.4rem',
    background: '#3e3d4ecc',
  },
  selectTagsButton: {
    border: 'none',
    display: 'flex',
    textDecoration: 'none',
    background: 'none',
    width: '100%',
    gap: '5px',
  },
  buttonWrapper: {
    background: '#1c1a2f',
    '&:hover': {
      background: "#3f3f4e",
    },
    padding: '0.2rem'
  },
  selectTagsContainer: {
    position: 'absolute',
    zIndex: '900',
    flexDirection: 'column',
    minWidth: '250px',
    display: 'flex',
    borderRadius: '5px',
    background: '#1c1a2f',
  },
  tagsOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0.5rem',
  }
});