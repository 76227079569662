import {observer} from "mobx-react";

function WizardComponent({wizard, steps, mainContainerStyle = {}}) {
  const CurrentStepComponent = steps[wizard.currentStep];
  const componentProps = wizard.stepNumber(wizard.currentStep);
  return (
    <div style={mainContainerStyle}>
      <CurrentStepComponent {...componentProps.asProps()}/>
    </div>
  )
}

export default observer(WizardComponent);