import {ListItemAvatar} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import {FileDownload} from '@mui/icons-material';
import * as React from 'react';
import ListEntryIcon from "./ListEntryIcon";

export function FileAvatarIcon({note, small=false}) {
  const smallStyle = small ? {width: 18, height: 18} : {};

  return (
    <ListItemAvatar>
      <ListEntryIcon small={small}>
        {note.isAnImage ? <ImageIcon style={smallStyle}/> : <FileDownload style={smallStyle}/>}
      </ListEntryIcon>
    </ListItemAvatar>
  );
}