import {computed, makeObservable, observable} from 'mobx';
import TextField from '../libs/forms/fields/TextField';
import MaiaForm from '../libs/forms/MaiaForm';
import EmailField from '../libs/forms/fields/EmailField';
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import {toast} from 'react-toastify';
import dayjs from 'dayjs';
import DateField from '../libs/forms/fields/DateField';
import EthnicGroupField from '../libs/forms/fields/custom_fields/EthnicGroupField';
import MultipleOptionsSectionsField from "../libs/forms/fields/MultipleOptionsSectionsField";
import {riskFactors} from "../app/Constants";
import BiologicalSexField from "../libs/forms/fields/custom_fields/BiologicalSexField";

export default class PatientProfileFeature {
  constructor(application) {
    this._application = application;
    this._form = undefined;

    makeObservable(this, {
      _form: observable,
      form: computed,
    })
  }

  load() {
    this.getPatientProfile();
  }

  _createFormFromProfile(profile) {
    const fields = {
      "firstName": new TextField(2, 20, profile.firstName()),
      "lastName": new TextField(2, 20, profile.lastName()).asOptional(),
      "email": new EmailField(profile.email()),
      "biologicalSex": new BiologicalSexField(profile.biologicalSex()).asOptional(),
      "birthdate": new DateField(profile.birthdate() ? dayjs(profile.birthdate()) : null).asOptional(),
      "identificationNumber": new TextField(3, 15, profile.identificationNumber()).asOptional(),
      "ethnicGroup": new EthnicGroupField(profile.ethnicGroup()).asOptional(),
      "familyHistory": new TextField(1, 5000, profile.familyHistory()).asOptional(),
      "riskFactors": new MultipleOptionsSectionsField(riskFactors, profile.riskFactors()).asOptional(),
    }

    return new MaiaForm(fields);
  }

  updatePatientProfile = () => {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        toast.error("No se puedo actualizar la información.");
        this.getPatientProfile();
      },
      handlesSuccess: () => {
        toast.success("Tus datos fueron actualizados!")
      }
    });
    this._application.apiClient().updatePatientProfile(this.form.values, responseHandler);
  }

  getPatientProfile() {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        toast.error("No se puedo obtener el perfil.")
      },
      handlesSuccess: (response) => {
        this.form = this._createFormFromProfile(response.profile());
      }
    });
    this._application.apiClient().getPatientProfile(responseHandler);
  }

  get isLoading() {
    return this._form === undefined;
  }

  get form() {
    return this._form;
  }

  set form(newForm) {
    this._form = newForm;
  }
}