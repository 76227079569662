import {useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import MaiaIconButton from '../buttons/MaiaIconButton';
import {secondsToMinutes} from '../../libs/utils';
import {LiveAudioVisualizer} from 'react-audio-visualize';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import {observer} from 'mobx-react';
import {useVoiceAudioRecorder} from '../../providers/VoiceAudioRecorderProvider';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom';
import MaiaLinearProgress from "../loaders/MaiaLinearProgress";

function VisualAudioRecorder() {
  const theme = useTheme();
  const voiceAudioRecorder = useVoiceAudioRecorder();
  const style = styles(theme);

  const personBeingRecord = voiceAudioRecorder.personBeingRecord;

  const uploadingAudioBox = () => {
    return (
      <Box sx={style.uploadingAudioContainer}>
        <Typography>
          Subiendo audio...
        </Typography>
        <MaiaLinearProgress/>
      </Box>
    )
  }

  return voiceAudioRecorder.shouldBeDisplayed && (
    <Box sx={style.audioRecorderContainer}>
      {voiceAudioRecorder.isUploadingAudio ? uploadingAudioBox() :
        <>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            {
              voiceAudioRecorder.isPaused ?
                <MaiaIconButton icon={PlayIcon}
                                title={'Reanudar grabación'}
                                onClick={voiceAudioRecorder.togglePauseResume}/>
                :
                <MaiaIconButton icon={PauseIcon}
                                title={'Pausar grabación'}
                                onClick={voiceAudioRecorder.togglePauseResume}/>
            }
            <MaiaIconButton icon={StopIcon}
                            title={'Detener grabación'}
                            onClick={voiceAudioRecorder.stopRecording}/>
          </Box>
          <div style={style.audioControlsContainer}>
            {
              personBeingRecord ?
                <Link to={personBeingRecord.path()}>
                  <Typography fontWeight={'600'}>
                    {personBeingRecord.displayInAudioPlayer()}
                  </Typography>
                </Link>
                :
                <Typography fontWeight={'600'}>
                  Mi nota
                </Typography>
            }
            <Box sx={{display: 'flex', flexDirection: 'row', gap: theme.spacing(1)}}>
              <Typography>
                {secondsToMinutes(voiceAudioRecorder.recordingTime)}
              </Typography>
              <div>
                {voiceAudioRecorder.mediaRecorder && (
                  <LiveAudioVisualizer
                    mediaRecorder={voiceAudioRecorder.mediaRecorder}
                    width={180}
                    height={24}
                    barColor={theme.colors.background}
                  />
                )}
              </div>
            </Box>
          </div>
          <MaiaIconButton icon={CloseIcon}
                          title={'Eliminar grabación'}
                          onClick={voiceAudioRecorder.deleteRecording}/>
        </>
      }
    </Box>
  )
}

export default observer(VisualAudioRecorder);

const styles = (theme) => ({
  audioRecorderContainer: {
    flex: 1,
    display: 'flex',
    background: theme.alerts.success,
    position: 'fixed',
    zIndex: 1000,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5em 0.7em',
    [theme.breakpoints.down('md')]: {
      top: 0,
    },
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(1),
      width: 'fit-content',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      borderRadius: '5px',
    }
  },
  audioControlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  uploadingAudioContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5em 0.7em',
  }
});
