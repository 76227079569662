import {action, computed, makeObservable, observable} from 'mobx';

export class AudioEntry {
  constructor({
                shared_id,
                name,
                title,
                isPublic,
                creationDate,
                publicUrl,
                hasFinished,
                hasStarted,
                summaryContent,
                tags,
              }) {
    this._shared_id = shared_id
    this._name = name
    this._title = title
    this._isPublic = isPublic
    this._creationDate = creationDate
    this._publicUrl = publicUrl
    this._hasFinished = hasFinished
    this._hasStarted = hasStarted
    this._summaryContent = summaryContent
    this._tags = tags

    makeObservable(this, {
      _title: observable,
      _isPublic: observable,
      title: computed,
      setVisibility: action,
    });
  }

  setVisibility(newValue) {
    this._isPublic = newValue;
  }

  get title() {
    return this._title;
  }

  get name() {
    return this._name;
  }

  get isPublic() {
    return this._isPublic;
  }

  get tags() {
    return this._tags;
  }

  get shared_id() {
    return this._shared_id;
  }

  get creationDate() {
    return this._creationDate;
  }

  get publicUrl() {
    return this._publicUrl;
  }

  get hasFinished() {
    return this._hasFinished;
  }

  get hasStarted() {
    return this._hasStarted;
  }

  get summaryContent() {
    return this._summaryContent;
  }

  get isAudio() {
    return true;
  }

  get isText() {
    return false;
  }
}