import { useTheme } from '@mui/material';
import { useApplication } from '../providers/ApplicationProvider';
import { useLocation } from 'react-router-dom';
import useFeature from '../hooks/useFeature';
import { observer } from 'mobx-react';
import { ScreenTitleBar } from '../components/ScreenTitleBar';
import * as React from 'react';
import TextNoteViewOrEdit from '../components/TextNoteViewOrEdit';
import SeeNotebookEntryFeature from '../features/SeeNotebookEntryFeature';

function SeeTextNoteScreen() {
  const theme = useTheme();
  const application = useApplication();
  const location = useLocation();
  const id = location.state.textNoteId;
  const feature = useFeature(() => new SeeNotebookEntryFeature(id, application), [location]);

  const style = styles(theme);

  return feature && (
    <section style={style.mainContainer}>
      <ScreenTitleBar title={feature.notebookEntry.title}/>
      <TextNoteViewOrEdit textNote={feature.notebookEntry}/>
    </section>
  );

}

export default observer(SeeTextNoteScreen);

const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection:
      'column',
    gap:
      '2rem',
  }
});