import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTheme} from "@mui/material";
import List from "@mui/material/List";
import MaiaLinearProgress from "./loaders/MaiaLinearProgress";

export default function ListOrNoElements({
                                           noElementMessage,
                                           elements,
                                           isRetrievingData,
                                           renderElement, listStyle,
                                           noElementIcon
                                         }) {
  const theme = useTheme();
  const style = styles(theme);

  const Icon = noElementIcon;

  if (elements.length === 0 && !isRetrievingData) {
    return (
      <Box sx={style.noElementsContainer}>
        <Icon style={{height: '2em', width: '2em'}}/>
        <Typography>
          {noElementMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {isRetrievingData ?
        <MaiaLinearProgress/>
        :
        <List sx={listStyle}>
          {
            elements.map((patient, index) => {
              return renderElement(patient, index);
            })
          }
        </List>
      }
    </>
  );
}

const styles = (theme) => ({
  noElementsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.padding.box,
    gap: '1rem',
    borderRadius: '5px',
    color: theme.colors.white,
    backgroundColor: theme.colors.lightBackground,
    border: `1px solid ${theme.colors.gray}`,
    width: '100%',
  }
});