export default class TextWithResourcesMessage {
  constructor(text, files) {
    this._text = text;
    this._files = files;
  }

  get text() {
    return this._text;
  }

  get files() {
    return this._files;
  }

  get wasSentByDoctor() {
    return false
  }
}