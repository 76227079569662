import {SuccessfulApiResponse} from '@eryxcoop/appyx-comm';
import NotebookEntryAdapter from "../adapters/NotebookEntryAdapter";

export default class GetNotebookEntryResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        entry: {
          'id': 1,
          'type': 'audio',
          'transcription': {
            'status': 'finished',
            'summary': 'a summary',
            'text': 'hello',
          },
          'shared_id': 'asdf-123',
          'tags': ['tag1', 'tag2'],
          'creation_date': '2021-01-02',
          'public_url': 'hello.wav',
          'name': 'note2.wav',
          'has_transcription': true,
          'summary_content': 'This is a summary',
        },
      }
    };
  }

  notebookEntry() {
    const entryAsJson = this.content().entry;
    return new NotebookEntryAdapter(entryAsJson).adapt();
  }
}