import { AuthenticationErrorResponse, RemoteRequester } from '@eryxcoop/appyx-comm';

export default class MaiaApiRequester extends RemoteRequester {
  constructor(app, url, authorizationManager) {
    super(url, authorizationManager);

    this._app = app;
  }

  call ({ endpoint, data = undefined, retries = 1 }) {
    return super.call({ endpoint, data }).then((response) => {
      if (response instanceof AuthenticationErrorResponse) {
        if(retries > 0) {
          this._refreshUserTokenAndRetry(endpoint, data, retries);
        } else {
          this._logOut();
        }
      }
      return response
    });
  }

  _logOut () {
    this._app.logOut();
  }

  _refreshUserTokenAndRetry (endpoint, data, retries) {
    this._app.refreshUserToken().then(() => {
      this.call({ endpoint, data, retries: retries - 1 }).then((response) => {
        return response;
      });
    });
  }
}