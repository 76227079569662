import {observer} from 'mobx-react';
import {Avatar, ListItemAvatar, useTheme} from "@mui/material";
import * as React from "react";

function ListEntryIcon({children, small=false}) {
  const theme = useTheme();
  const style = styles(theme);

  const smallStyles = small ? {width: 32, height: 32} : {};

  return (
    <ListItemAvatar>
      <Avatar sx={{...style.avatarStyle, ...smallStyles}}>
        {children}
      </Avatar>
    </ListItemAvatar>
  )
}

export default observer(ListEntryIcon);

const styles = (theme) => ({
  avatarStyle: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});