import {Avatar, useTheme} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import Typography from "@mui/material/Typography";
import {pluralize} from "../../libs/utils";
import Box from "@mui/material/Box";
import HealingIcon from "@mui/icons-material/Healing";
import Markdown from "react-markdown";
import MaiaIconButton from "../buttons/MaiaIconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as React from "react";
import MaiaDotsLoader from "../loaders/MaiaDotsLoader";

export default function ChatbotAnswer({chatBot, message, isLoading = false}) {

  const theme = useTheme();
  const style = styles();

  const entriesUsedPresented = (entriesUsed) => {
    const entriesPresented = entriesUsed.map((entry) => {
      return (
        <button key={entry.title} style={style.entryBoxContainer} onClick={() => {
        }}>
          <DescriptionIcon style={style.fileIconStyle}/>
          <Typography color={theme.text.primary}>
            {entry.title}
          </Typography>
        </button>
      );
    });
    return (
      <>
        <Typography color={theme.text.darkGrey}>
          {pluralize('Fuente', entriesUsed.length)} {pluralize('utilizada', entriesUsed.length, false)}:
        </Typography>
        <Box>
          {entriesPresented}
        </Box>
      </>
    )
  }

  const assistantAvatar = () => {
    return (
      <Avatar sx={style.avatarStyle}>
        <HealingIcon style={style.assistantIconStyle}/>
      </Avatar>
    );
  }

  if (isLoading) {
    return (
      <Box style={style.loadingAnswerContainer}>
        {assistantAvatar()}
        <Box style={style.answerBoxContainer}>
          <MaiaDotsLoader/>
        </Box>
      </Box>
    )
  }

  const textAnswer = message.text;
  const entriesUsed = message.files;

  return (
    <Box style={{display: 'flex', gap: '15px'}}>
      {assistantAvatar()}
      <Box style={style.answerBoxContainer}>
        <Typography color={theme.text.primary} style={{margin: '0px'}}>
          <Markdown>
            {textAnswer}
          </Markdown>
        </Typography>
        {entriesUsedPresented(entriesUsed)}
        <MaiaIconButton icon={ContentCopyIcon}
                        iconColor={'white'}
                        iconSize={'0.8em'}
                        title={'Copiar'}
                        onClick={() => chatBot.onCopy(textAnswer)}/>
      </Box>
    </Box>
  )
}

const styles = () => ({
    entryBoxContainer: {
      background: 'none',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      width: 'fit-content',
      border: 'none'
    },
    answerBoxContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    },
    loadingAnswerContainer: {
      display: 'flex', gap: '15px', alignItems: 'center'
    },
    avatarStyle: {
      background: 'none',
      border: 'white solid 1px',
    },
    assistantIconStyle: {
      height: '1em',
      width: '1em',
      color: 'white'
    },
    fileIconStyle: {
      height: '0.8em',
      width: '0.8em'
    }
  })
;