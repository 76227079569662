import {useTheme} from '@mui/material';
import {useApplication} from '../../providers/ApplicationProvider';
import {observer} from "mobx-react";
import PatientLoginFeature from "../../features/logins/PatientLoginFeature";
import LoginBox from "../../components/LoginBox";
import {useNavigate} from "react-router-dom";
import useFeature from '../../hooks/useFeature';

function PatientLoginScreen() {
  const theme = useTheme();
  const app = useApplication();
  const navigate = useNavigate();
  const feature = useFeature(() => new PatientLoginFeature(app, navigate), [app, navigate]);
  const style = styles(theme);

  return feature && (
    <main style={style.mainContainer}>
      <LoginBox login={feature.loginPatient}
                fontColor={theme.colors.background}
                pathToOtherLogin={'/login/doctor'}
                subtitleOtherLoginLink={"¿Eres doctor? Inicia sesión aquí."}
                subtitle={"pacientes"}/>
    </main>
  );
}

export default observer(PatientLoginScreen);

const styles = (theme) => ({
  mainContainer: {
    backgroundColor: theme.colors.lightGrey,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
});