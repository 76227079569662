import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import MaiaFilledIconButton from "../buttons/MaiaFilledIconButton";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";

export default function ChatbotSideBar({chatBot}) {
  const theme = useTheme();
  const style = styles(theme);

  const chatHistory = chatBot.chatHistory;

  return (
    <Box sx={style.actionsBarContainer}>
      <MaiaFilledIconButton icon={SaveIcon}
                            backgroundColor={'#15152c'}
                            iconColor={theme.palette.primary.main}
                            title={'Guardar como nota'}
                            onClick={() => chatBot.saveConversation()}/>
      <MaiaFilledIconButton icon={DeleteIcon}
                            backgroundColor={'#15152c'}
                            iconColor={theme.palette.primary.main}
                            title={'Limpiar conversación'}
                            onClick={() => chatHistory.clear()}/>
    </Box>
  )
}

const styles = (theme) => ({
    actionsBarContainer: {
      background: '#15152c',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    }
  })
;