export default class NotificationReceiver {
  constructor () {
    this._subscriptions = [];
  }

  messageReceived = (message) => {
    if (message === 'pong') {
      return;
    }

    const notification = JSON.parse(message);
    this._subscriptions.forEach(subscription => {
      if (subscription.type === notification.type) {
        subscription.onNotificationReceived(notification.content);
      }
    });
  };

  subscribeToNotifications = (subscriber, type, onNotificationReceived) => {
    this._subscriptions.push({ type, subscriber, onNotificationReceived });
  };

  unsubscribeFromAll = (subscriber) => {
    this._subscriptions = this._subscriptions.filter(subscription => subscription.subscriber !== subscriber);
  };
}