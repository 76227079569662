import {Dialog, DialogContent, DialogTitle, useTheme} from "@mui/material";
import {useState} from "react";
import MaiaFilledIconButton from "../buttons/MaiaFilledIconButton";

export default function ButtonDialog({
                                       icon,
                                       iconColor,
                                       iconHoverTitle,
                                       title,
                                       onOpen = () => {
                                       },
                                       children
                                     }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  }

  return (
    <>
      <MaiaFilledIconButton
        icon={icon}
        onClick={(e) => {
          e.preventDefault();
          onOpen();
          setOpen(true);
        }}
        iconColor={iconColor}
        title={iconHoverTitle}/>
      <Dialog
        open={open}
        bodyStyle={{
          padding: theme.spacing(2),
          zIndex: 1000
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "80%",
              maxWidth: "600px"
            },
          },
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="dialog-title" style={{color: 'grey'}}>
          {title}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </>
  )
}