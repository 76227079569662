import OptionField from './OptionField';

export default class OptionLabeledField extends OptionField {
  constructor(labeledOptions, initialValue = undefined) {
    const labeledValueOptions = labeledOptions.map((option) => {
      return option.value;
    });
    super(labeledValueOptions, initialValue);
    this._options = labeledOptions;
  }

  options() {
    return this._options;
  }

  get labelValue() {
    return this._options.find((option) => option.value === this.value).label;
  }
}
