import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextNoteViewOrEdit from './TextNoteViewOrEdit';
import * as React from 'react';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorBox from './ErrorBox';

export default function Summary ({ summary }) {
  const theme = useTheme();
  const style = styles(theme);

  return (
    <Box style={style.summaryContainer}>
      <Typography variant="h6" color={theme.text.primary} fontWeight={'700'}>
        Resumen
      </Typography>
      {summary ?
        <TextNoteViewOrEdit textNote={summary}/> :
        <ErrorBox errorIcon={HourglassTopIcon} errorMessage={"El resumen todavía no está disponible."}/>
      }
    </Box>
  );
}

const styles = () => ({
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '1rem',
  }
});