import MaiaForm from '../libs/forms/MaiaForm';
import TextField from '../libs/forms/fields/TextField';
import {action, computed, makeObservable, observable} from 'mobx';
import {toast} from 'react-toastify';
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';

export class SettingsFeature {
  constructor(application) {
    this._application = application;
    this._tags = [];
    this._newTagForm = new MaiaForm({
      'name': new TextField(2, 50),
    });

    makeObservable(this, {
      _tags: observable,
      tags: computed,
      _updateTags: action,
    });
  }

  load() {
    this._refreshTags();
  }

  _refreshTags() {
    this._application.notebookConfiguration().getTags((tags) => this._updateTags(tags));
  }

  _updateTags(tags) {
    this._tags = tags;
  }

  createNewTag = () => {
    if (!this._newTagForm.isValid) {
      toast.error('La etiqueta debe tener entre 2 y 50 caracteres');
      return;
    }

    const tagName = this._newTagForm.getFieldByName('name').value;
    this._createTagIfPossible(tagName);
    this._newTagForm.clear();
  };

  _createTagIfPossible(tagName) {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
        this._refreshTags();
      },
      handlesError: () => {
        toast.error('Error al crear tag');
      }
    });
    this._application.apiClient().createNotebookTag(tagName, responseHandler);
  }

  deleteTag = (tag) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
        this._refreshTags();
      },
      handlesError: () => {
        toast.error('Error al eliminar tag');
      }
    });
    this._application.apiClient().deleteNotebookTag(tag.id, responseHandler);
  };

  editTagName = (tag, newName) => {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
        this._refreshTags();
      },
      handlesError: () => {
        toast.error('Error al editar tag');
      }
    });
    this._application.apiClient().editNotebookTag(tag.id, newName, responseHandler);
  }

  get tags() {
    return this._tags;
  }
}