import {action, computed, makeObservable, observable} from "mobx";
import TextMessage from "./TextMessage";
import {capitalize} from "../libs/utils";
import TextWithResourcesMessage from "./TextWithResourcesMessage";

export default class ChatHistory {
  constructor() {
    this._messages = [];

    makeObservable(this, {
      _messages: observable,
      allMessages: computed,
      isEmpty: computed,
      addQuestion: action,
      addAssistantAnswer: action,
      clear: action
    });
  }

  addQuestion(textMessage) {
    const message = TextMessage.fromDoctor(textMessage);
    this._messages.push(message);
  }

  addAssistantAnswer(textMessage, files) {
    const message = new TextWithResourcesMessage(capitalize(textMessage), files);
    this._messages.push(message);
  }

  clear() {
    this._messages = [];
  }

  get allMessages() {
    return this._messages;
  }

  get isEmpty() {
    return this._messages.length === 0;
  }
}