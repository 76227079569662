import ApiResponseHandler from "@eryxcoop/appyx-comm/src/errors/ApiResponseHandler";

export default class NotebookConfiguration {

  constructor(application) {
    this._application = application;
  }

  getTags(onSuccess) {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        onSuccess(response.notebookConfiguration().tags);
      },
      handlesError: () => {
        console.log('Error al cargar tags');
      }
    });

    this._application.apiClient().getNotebookConfiguration(responseHandler);
  }
}