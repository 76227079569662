import {SuccessfulApiResponse} from "@eryxcoop/appyx-comm";
import SharePermission from "../../../domain/SharePermission";

export default class SharePermissionsResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        share_permissions: [
          {
            doctor_full_name: 'Delfi Brea',
            email: 'delfi@brea.com',
            expiration_datetime: '2026-09-01T00:00:00Z',
          },
          {
            doctor_full_name: 'Bea Gonzalez',
            email: 'bea@gonzales.com',
            expiration_datetime: '2025-10-01T00:00:00Z',
          }
        ]
      },
    }
  }

  sharePermissions() {
    return this.content().share_permissions.map((sharePermission) => {
      return new SharePermission(sharePermission.doctor_full_name, sharePermission.email,
        sharePermission.expiration_datetime);
    });
  }
}