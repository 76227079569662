import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import { action, computed, makeObservable, observable } from 'mobx';

export default class SeeNotebookEntryFeature {
  constructor(id, application) {
    this._application = application;
    this._id = id;
    this._notebookEntry = "";

    makeObservable(this, {
      _notebookEntry: observable,
      _updateNotebookEntry: action,
      notebookEntry: computed,
    });
  }

  load() {
    this._loadNotebookEntry();
  }

  _loadNotebookEntry() {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._updateNotebookEntry(response.notebookEntry());
      }
    });
    this._application.apiClient().getNotebookEntry(this._id, responseHandler);
  }

  _updateNotebookEntry(notebookEntry) {
    this._notebookEntry = notebookEntry;
  }

  get notebookEntry() {
    return this._notebookEntry;
  }
}