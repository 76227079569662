import {SuccessfulApiResponse} from '@eryxcoop/appyx-comm';
import TextNoteAdapter from '../adapters/TextNoteAdapter';
import ListAdapter from "../adapters/general/ListAdapter";
import AudioNoteAdapter from "../adapters/AudioNoteAdapter";
import FileAdapter from "../adapters/FileAdapter";

export default class GetNotebookResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        notebook: {
          shared_id: '12312-asdf-123',
          audio_notes: [
            {
              "id": 1,
              "transcription": {
                "status": 'finished',
                "summary": 'a summary',
                "text": 'hello',
              },
              "shared_id": "asdf-123",
              "tags": ["tag1", "tag2"],
              "creation_date": "2021-01-02",
              "public_url": "hello.wav",
              "name": 'note2.wav',
              "has_transcription": true,
              "summary_content": "This is a summary",
            }
          ],
          text_notes: [
            {
              "shared_id": "asdf-123",
              "tags": ["tag1"],
              "creation_date": "2021-01-01",
              "content": "asdf.wav",
              "id": "asdf-123"
            },
          ],
          files: [
            {
              "shared_id": "asdf-123",
              "tags": [],
              "name": "hello.pdf",
              "path": "a_path",
              "public_url": "as.com",
              "creation_date": "4/5/2024",
              "is_an_image": false
            },
          ]
        }
      },
    }
  }

  notebookEntries() {
    const text_notes = new ListAdapter(this.content().notebook.text_notes, TextNoteAdapter).adapt();
    const audio_notes = new ListAdapter(this.content().notebook.audio_notes, AudioNoteAdapter).adapt();
    const files = new ListAdapter(this.content().notebook.files, FileAdapter).adapt();
    const notes = text_notes.concat(audio_notes).concat(files);
    return notes.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
  }
}