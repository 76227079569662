import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from "react";

// TODO: Move this to a basics folder of components, in order to find it more easily

export default function MaiaAccordion({children, elements, renderElement, styles={}}) {
  return (
    <Accordion style={styles}>
      <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon/>}>
        {children}
      </AccordionSummary>
      <AccordionDetails>
        {
          elements.map((element) => {
            return renderElement(element);
          })
        }
      </AccordionDetails>
    </Accordion>
  );
}