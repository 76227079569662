import {Accordion, AccordionDetails, AccordionSummary, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import * as React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import {dayAndHoursDistanceFromMessage, pluralize} from "../libs/utils";
import ValidateActionButtonDialog from "./dialogs/ValidateActionButtonDialog";

export default function SharePermissionsBox({sharePermissions, onRemoveSharePermission}) {
  const theme = useTheme();
  const style = styles(theme);

  const expirationMessage = (sharePermission) => {
    if (sharePermission.hasNoExpiration()) {
      return 'Por tiempo indefinido';
    }
    return `Vencerá en ${dayAndHoursDistanceFromMessage(sharePermission.expirationDatetime())}`;
  }

  const getIconColor = (sharePermission) => {
    if (sharePermission.hasNoExpiration()) {
      return theme.colors.darkGrey;
    }
    return sharePermission.expiresInLessThanADay() ? theme.palette.violet.dark : theme.palette.error.main;
  }

  return (
    <Accordion>
      <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon/>}>
        Estas compartiendo tu legajo con&nbsp; <span
        style={style.spanStyle}>{pluralize('médico', sharePermissions.length)}</span>
      </AccordionSummary>
      <AccordionDetails>
        {
          sharePermissions.map((sharePermission) => {
            return (
              <Box style={style.rowContainer} key={sharePermission.doctorFullName()}>
                <HistoryToggleOffIcon style={{...style.icon, color: getIconColor(sharePermission)}}/>
                <Typography>
                  Estás compartiendo tu legajo
                  con {sharePermission.doctorFullName()}. {expirationMessage(sharePermission)}
                </Typography>
                <ValidateActionButtonDialog
                  icon={CancelIcon}
                  iconHoverTitle={'Dejar de compartir'}
                  acceptLabel={'Confirmar'}
                  extraButtonStyles={{marginLeft: 'auto'}}
                  onConfirm={() => onRemoveSharePermission(sharePermission.doctorEmail())}
                  title={'Vas a dejar de compartir tu legajo.'}
                  subText={'¿Estás seguro de que querés dejar de compartir?'}
                />
              </Box>
            );
          })
        }
      </AccordionDetails>
    </Accordion>
  );
}

const styles = (theme) => ({
  rowContainer: {
    display: 'flex',
    gap: '8px',
    padding: '8px 0px',
  },
  spanStyle: {
    fontWeight: 'bold',
    color: theme.palette.violet.dark
  },
  icon: {
    height: '1em',
    width: '1em'
  }
})