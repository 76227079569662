import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useRef} from "react";
import {observer} from "mobx-react";
import Line from "../Line";
import ChatBotSendMessageBar from "./ChatBotSendMessageBar";
import Typography from "@mui/material/Typography";
import EntriesUsedAccordion from "./EntriesUsedAccordion";
import ChatbotSideBar from "./ChatbotSideBar";
import ChatbotAnswer from "./ChatbotAnswer";
import ChatButton from "./ChatButton";

function ChatBox({chatBot, showDocumentSearch}) {
  const theme = useTheme();
  const chatRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const style = styles(theme);

  const chatHistory = chatBot.chatHistory;

  const initialQuestions = [
    ['📜 Enfermedades preexistentes', 'Enfermedades preexistentes'],
    ['🤒 Ultimas enfermedades', 'Ultimas enfermedades'],
    ['🩸 Estudios de sangre', 'Estudios de sangre']
  ]

  const _askChatbot = async (question) => {
    await chatBot.ask(question);
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }

  const renderQuestion = (question) => {
    return (
      <Box style={{...style.questionBoxContainer, marginLeft: 'auto'}}>
        <Typography color={theme.text.primary}>
          {question}
        </Typography>
      </Box>
    )
  }

  const renderMessages = (chatHistory) => {
    return chatHistory.allMessages.map((message) => {
      return message.wasSentByDoctor ? renderQuestion(message.text) :
        <ChatbotAnswer message={message} chatBot={chatBot}/>
    })
  }

  const renderMessageOptions = () => {
    return (
      <Box style={style.optionsContainer}>
        <Typography color={theme.text.primary} fontWeight={'600'}>
          Comenzá a consultar.
        </Typography>
        {
          initialQuestions.map((option) => {
            return (
              <button key={option[1]} style={style.questionBoxContainer} onClick={async () => {
                await _askChatbot(option[1])
              }}>
                <Typography color={theme.text.primary}>
                  {option[0]}
                </Typography>
              </button>
            )
          })
        }
      </Box>
    )
  }

  return (
    <>
      <ChatButton onClick={() => setOpen(!open)}/>
      {open &&
        <>
          <Box sx={style.mainContainer}>
            <EntriesUsedAccordion chatBot={chatBot}/>
            <Box sx={style.xxx}>
              <Box style={style.chatContainer}>
                <Box ref={chatRef} style={style.answersContainer}>
                  {chatHistory.isEmpty ? renderMessageOptions() : renderMessages(chatHistory)}
                  {chatBot.isRetrievingAnswer && <ChatbotAnswer isLoading={true} chatBot={chatBot}/>}
                </Box>
                <Line extraStyles={style.extraStylesLine}/>
                <ChatBotSendMessageBar
                  onSearch={_askChatbot}
                  searchDisabled={chatBot.isRetrievingAnswer}
                  showDocumentSearch={showDocumentSearch}
                  placeholder={'Buscá o preguntá sobre tus documentos.'}/>
              </Box>
              <ChatbotSideBar chatBot={chatBot}/>
            </Box>
          </Box>
        </>
      }
    </>

  )
}

export default observer(ChatBox);

const styles = (theme) => ({
    mainContainer: {
      display: 'flex',
      bottom: '65px',
      right: '5px',
      position: 'fixed',
      zIndex: '2',
      flexDirection: 'column',
      width: '70%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    chatContainer: {
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.primary.dark,
      padding: '15px',
      flex: 1,
    },
    xxx: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    optionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    answersContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      height: '400px',
      overflowY: 'scroll',
      padding: '10px 0',
      msOverflowStyle: "none", // For Internet Explorer 10+
    },
    questionBoxContainer: {
      background: theme.palette.lilac.dark,
      width: 'fit-content',
      padding: '8px',
      borderRadius: '5px',
      maxWidth: '80%',
      border: 'none'
    },
    extraStylesLine: {
      margin: '10px 0'
    },
  })
;