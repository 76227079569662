import { action, computed, makeObservable, observable } from 'mobx';

export class FileEntry {
  constructor ({
    id,
    shared_id,
    name,
    title,
    isPublic,
    publicUrl,
    path,
    creationDate,
    isAnImage,
    tags,
  }) {
    this._id = id;
    this._shared_id = shared_id;
    this._name = name;
    this._title = title;
    this._isPublic = isPublic;
    this._publicUrl = publicUrl;
    this._path = path;
    this._creationDate = creationDate;
    this._isAnImage = isAnImage;
    this._tags = tags;

    makeObservable(this, {
      _isPublic: observable,
      title: computed,
      setVisibility: action,
    });
  }

  setVisibility (newValue) {
    this._isPublic = newValue;
  }

  get id () {
    return this._id;
  }

  get shared_id () {
    return this._shared_id;
  }

  get name () {
    return this._name;
  }

  get title () {
    return this._title;
  }

  get isPublic () {
    return this._isPublic;
  }

  get publicUrl () {
    return this._publicUrl;
  }

  get path () {
    return this._path;
  }

  get creationDate () {
    return this._creationDate;
  }

  get isAnImage () {
    return this._isAnImage;
  }

  get tags () {
    return this._tags;
  }

  get isFile () {
    return true;
  }

  get isText () {
    return false;
  }
}