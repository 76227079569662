import {SuccessfulApiResponse} from "@eryxcoop/appyx-comm";
import Profile from '../../../domain/Profile';

export default class ProfileResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        profile: {
          first_name: 'Delfi',
          last_name: 'Brea',
          full_name: 'Delfi Brea',
          email: 'delfi@brea.com',
          biological_sex: 'FEMALE',
          birthdate: '1990-01-01',
          identification_number: '123456789',
        }
      },
    }
  }

  profile() {
    return Profile.fromResponse(this.content().profile);
  }
}