import * as React from "react";
import {observer} from "mobx-react";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import EditOffIcon from '@mui/icons-material/EditOff';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorBox from "./ErrorBox";
import CopyTextContainer from "./CopyTextContainer";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

function Transcription({audioNoteTranscriptionDown, audioNoteTranscription}) {
  const theme = useTheme();

  if (audioNoteTranscriptionDown) {
    return (<ErrorBox errorIcon={SyncProblemIcon}
                      errorMessage={"No se puede obtener la transcripción de la nota de audio. Intente nuevamente más tarde!"}/>)
  }

  if (audioNoteTranscription.status.failed) {
    return (<ErrorBox errorIcon={EditOffIcon} errorMessage={"No se puede transcribir la nota de audio."}/>);
  }

  if (!audioNoteTranscription.status.started) {
    return (
      <ErrorBox errorIcon={HourglassTopIcon} errorMessage={"La transcripción todavía no está disponible."}/>);
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
      <Typography variant="h6" color={theme.text.primary} fontWeight={'700'}>
        Transcripción
      </Typography>
      <CopyTextContainer textForClipboard={audioNoteTranscription.text}>
        {audioNoteTranscription.text}
      </CopyTextContainer>
    </Box>
  )
}

export default observer(Transcription);