import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MaiaIconButton from "../buttons/MaiaIconButton";
import {Close} from "@mui/icons-material";
import NotesSearchResultsList from "./NotesSearchResultsList";
import * as React from "react";
import {useTheme} from "@mui/material";
import {observer} from "mobx-react";

function NotesSearchResults({searchedEntries, closeSearchResults, isRetrievingData,  owner, showSearchResults}) {
  const theme = useTheme();
  const style = styles(theme);

  return showSearchResults && (
    <Box sx={style.queryResults}>
      <Box sx={style.queryResultsHeader}>
        <Typography color={theme.text.primary} fontWeight={'700'}>
          ✨ Resultados de la búsqueda
        </Typography>
        <MaiaIconButton onClick={closeSearchResults} icon={Close} title="Cerrar"
                        iconColor={theme.colors.defaultIconButton}/>
      </Box>
      <NotesSearchResultsList
        noteSearchResults={searchedEntries}
        isRetrievingData={isRetrievingData}
        audioNotesOwner={owner}
      />
    </Box>
  )
}

export default observer(NotesSearchResults);

const styles = (theme) => ({
  queryResults: {
    backgroundColor: theme.colors.lightBackground,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px',
    gap: '16px',
  },
  queryResultsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});