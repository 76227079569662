import {observer} from "mobx-react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function SharingSuccessfulStep() {
  const style = styles();
  return (
    <>
      <Box style={style.successfulResultContainer}>
        <CheckCircleIcon style={style.iconStyle}/>
        <Typography fontWeight={'bold'}>
          ¡Se compartió con éxito el legajo!
        </Typography>
        <Typography>
          Ahora el doctor podrá ver tu información.
        </Typography>
      </Box>
    </>
  );
}

export default observer(SharingSuccessfulStep);

const styles = () => ({
  successfulResultContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 1rem',
  },
  iconStyle: {
    color: '#57c757',
    marginBottom: '0.5rem',
    height: '100px',
    width: '100px'
  }
});