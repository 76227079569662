import ApiResponseHandler from "@eryxcoop/appyx-comm/src/errors/ApiResponseHandler";

export default class PatientHomeFeature {
  constructor(application, navigate, isComingFromLogIn) {
    this._application = application;
    this._navigate = navigate;
    this._isComingFromLogIn = isComingFromLogIn;
  }

  load() {
    if (this._isComingFromLogIn) {
      this.redirectToProfileIfNotCompleted();
    }
  }

  redirectToProfileIfNotCompleted() {
    const profileResultHandler = new ApiResponseHandler(
      {
        handlesSuccess: (profileResponse) => {
          if (!profileResponse.profile().isCompleted()) {
            this._navigate('/profile');
          }
        }
      });

    this._application.apiClient().getPatientProfile(profileResultHandler);
  }
}