import dayjs from "dayjs";

export default class IsADateValidator {
  isValid(value) {
    return value && dayjs(value).isValid();
  }

  errorMessage() {
    return "Fecha inválida";
  }
}
