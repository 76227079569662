import * as React from 'react';
import NotebookEntryListItem from './NotebookEntryListItem';
import {FileAvatarIcon} from '../FileAvatarIcon';

export default function FileListItem({
                                       note,
                                       tags,
                                       deleteNote,
                                       toggleEntryVisibility,
                                       updateFileTitle,
                                       updateEntryTags,
                                       addEntryToChatbot
                                     }) {
  return (
    <NotebookEntryListItem
      deleteNote={deleteNote}
      addEntryToChatbot={addEntryToChatbot}
      tags={tags}
      updateEntryTags={updateEntryTags}
      updateTitle={updateFileTitle}
      toggleEntryVisibility={toggleEntryVisibility}
      avatarIcon={<FileAvatarIcon note={note}/>}
      linkToNote={`image/${note.shared_id}`}
      note={note}
      text={note.summaryContent}
      linkShareInfo={{imagePath: note.publicUrl, creationDate: note.creationDate}}
      showBadges={() => <></>}
    />
  );
}
