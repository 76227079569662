import {SuccessfulApiResponse} from '@eryxcoop/appyx-comm';
import ListAdapter from "../adapters/general/ListAdapter";

class NotebookTagAdapter {
  constructor(tag) {
    this._tag = tag;
  }

  adapt() {
    return {
      id: this._tag.id,
      name: this._tag.name,
    }
  }
}

export default class GetNotebookConfigurationResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        notebook_configuration: {
          tags: [
            {
              "id": "vamosdelfiii",
              "name": "vamosdelfiii",
            }
          ],
        }
      },
    }
  }

  notebookConfiguration() {
    const tags = new ListAdapter(this.content().notebook_configuration.tags, NotebookTagAdapter).adapt();
    return {tags}
  }
}