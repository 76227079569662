import {isDateFurtherThanDays} from "../libs/utils";

export default class SharePermission {
  constructor(doctorFullName, email, expirationDatetime) {
    this._doctorFullName = doctorFullName;
    this._email = email;
    this._expirationDatetime = expirationDatetime;
  }

  doctorFullName() {
    return this._doctorFullName;
  }

  doctorEmail() {
    return this._email;
  }

  forDoctor(doctorEmail) {
    return this._email === doctorEmail;
  }

  expirationDatetime() {
    return new Date(this._expirationDatetime);
  }

  expiresInLessThanADay() {
    return isDateFurtherThanDays(this._expirationDatetime, 1);
  }

  hasNoExpiration() {
    return !this._expirationDatetime;
  }
}