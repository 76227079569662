import { Autocomplete, TextField, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import FormFieldBase from './FormFieldBase';

function FormAutocompleteField ({
  config,
  label = undefined,
  field,
  extraLabelStyle = {},
  width = '100%',
  disabled = false,
  onChange = () => {
  },
  ...props
}) {
  const theme = useTheme();
  const style = styles(theme, width);
  const options = config.options;
  const groupOptionsBy = config.groupOptionsBy;
  const getOptionLabel = config.getOptionLabel;
  const isOptionEqualToValue = config.isOptionEqualToValue;
  const getValue = config.getValue;
  const value = options.find(option => isOptionEqualToValue(option, field.value)) || "";

  return (
    <FormFieldBase label={label} field={field} extraLabelStyle={extraLabelStyle}>
      <Autocomplete
        disabled={disabled}
        error={field.hasErrors}
        style={style.fieldContainer}
        value={value}
        size="small"
        onBlur={() => {
          field.validate();
        }}
        onChange={(e, values) => {
          const newValue = getValue(values)
          field.updateValue(newValue);
          onChange(newValue);
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        groupBy={groupOptionsBy}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (<TextField {...params}/>)}
        {...props}
      />
    </FormFieldBase>
  );
}

const styles = (theme, width) => ({
  fieldContainer: {
    backgroundColor: theme.fields.background,
    justifyContent: 'center',
    borderRadius: '5px',
    width: width,
  }
});

export default observer(FormAutocompleteField);