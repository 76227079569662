import MultipleOptionsValidator from "./validators/MultipleOptionsValidator";
import FormField from "./FormField";

/*
* Options must look like:
* [ {section: "name", subsection: [{code: "code", name: "name"}, ...]}, ...]
*/

export default class MultipleOptionsSectionsField extends FormField {
  constructor(options, initialValue = undefined) {
    const codeOptions = [];
    options.forEach((option) => {
      option.subsection.forEach((risk) => {
        codeOptions.push(risk.code);
      });
    });
    super(initialValue);
    this._validators = [new MultipleOptionsValidator(codeOptions)];
    this._options = options;
  }

  defaultValue() {
    return [];
  }

  options() {
    return this._options;
  }

  addValue(value) {
    this.updateValue([...this.value, value])
  }

  removeValue(value) {
    this.updateValue(this.value.filter((v) => v !== value));
  }

  hasValue(value) {
    return this.value.includes(value);
  }

  get labeledValues() {
    const labelsSelected = this.value.map((value) => {
      const option = this._options.find((option) => option.subsection.find((subOption) => subOption.code === value));
      const subOption = option.subsection.find((subOption) => subOption.code === value);
      return subOption.name
    });
    return labelsSelected.join(". ");
  }

}
