import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function FormFieldBase({field, label = undefined, extraLabelStyle = {}, children}) {
  const theme = useTheme();
  const style = styles(theme);

  return (
    <div style={style.fieldContainer}>
      {label &&
        <Typography variant="body1" style={{...style.labelStyle, ...extraLabelStyle}}>
          {label} {field.isRequired ? <span> *</span> : <></>}
        </Typography>
      }
      {children}
    </div>
  );
}

const styles = (theme) => ({
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    gap: theme.spacing(1),
  },
  labelStyle: {
    fontWeight: '800',
    backgroundColor: theme.colors.labelBackground,
    padding: '0.5rem',
    borderRadius: '5px',
    color: theme.colors.lightBlue,
  },
});