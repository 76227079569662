import * as React from 'react';
import Typography from '@mui/material/Typography';
import FilledButton from '../components/buttons/FilledButton';
import {useTheme} from '@mui/material';
import {useApplication} from '../providers/ApplicationProvider';
import PatientsListFeature from '../features/PatientsListFeature';
import {observer} from 'mobx-react';
import useFeature from '../hooks/useFeature';
import {useNavigate} from 'react-router-dom';

// icons
import AddIcon from '@mui/icons-material/Add';
import FormTextField from '../components/fields/FormTextField';
import {useVoiceAudioRecorder} from '../providers/VoiceAudioRecorderProvider';
import Divider from '@mui/material/Divider';
import {PatientListItem} from '../components/PatientListItem';
import Box from '@mui/material/Box';
import {ScreenTitleBar} from '../components/ScreenTitleBar';
import ListOrNoElements from "../components/ListOrNoElements";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PatientOfDoctor from '../app/audioOwners/PatientOfDoctor';
import DashboardMainContainer from "../components/DashboardMainContainer";

function PatientsListScreen() {
  const theme = useTheme();
  const application = useApplication();
  const voiceAudioRecorder = useVoiceAudioRecorder();
  const navigator = useNavigate();
  const feature = useFeature(() => new PatientsListFeature(application, navigator));
  const style = styles(theme);

  const renderListElement = (patient, index) => {
    return (
      <Box key={`patient-${index}`}>
        <PatientListItem
          patient={patient}
          canRecord={!voiceAudioRecorder.isRecording}
          isRecording={voiceAudioRecorder.patientBeingRecord === patient}
          stopRecording={voiceAudioRecorder.stopRecording}
          startRecording={() => voiceAudioRecorder.startRecording(new PatientOfDoctor(patient))}
        />
        {index < feature.patients.length - 1 && <Divider variant="inset" component="li"/>}
      </Box>
    );
  }

  return feature && (
    <DashboardMainContainer>
      <Box sx={style.headerContainer}>
        <ScreenTitleBar title={'Pacientes'} showBackButton={false}>
          <FilledButton onClick={feature.goToAddNewPatient} style={style.newSessionButton}>
            <AddIcon color="white"/>
            <Typography>Nueva sesión</Typography>
          </FilledButton>
        </ScreenTitleBar>
      </Box>
      <Box sx={style.listContainer}>
        <Box sx={style.filtersContainer}>
          <Box sx={style.searchContainer}>
            <FormTextField
              field={feature.searchField}
              placeholder={'Buscar paciente'}
              onChange={feature.search}
            />
          </Box>
        </Box>
        <ListOrNoElements noElementIcon={PersonAddIcon}
                          isRetrievingData={feature.isRetrievingData}
                          elements={feature.patients}
                          listStyle={style.patientList}
                          renderElement={renderListElement}
                          noElementMessage={'No hay pacientes todavía!'}/>
      </Box>
    </DashboardMainContainer>
  );
}

export default observer(PatientsListScreen);

const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  filtersContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    borderRadius: '5px',
    gap: theme.spacing(2),
  },
  searchContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '30%',
      marginLeft: 'auto'
    },
    flex: 1,
    gap: theme.spacing(1),
  },
  patientList: {
    width: '100%',
    background: theme.colors.white,
    borderRadius: '5px'
  },
  newSessionButton: {
    marginLeft: 'auto',
    gap: theme.spacing(1)
  }
});