import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs, useTheme } from '@mui/material';

export function MaiaTabs ({ tabs, initialTab = 0 }) {
  const [tabNumber, setTabNumber] = useState(initialTab);
  const theme = useTheme();

  return (
    <Box>
      <Tabs sx={{background: theme.colors.white, borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} value={tabNumber}
            onChange={(event, newTab) => setTabNumber(newTab)} aria-label="patient medical record tabs">
        {tabs.map((tab, index) => (
          <Tab key={`tab-selector-${index}`} label={tab.title}/>
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <Box key={`tab-${index}`}>
          {tabNumber === index && tab.content()}
        </Box>
      ))}
    </Box>
  );
}