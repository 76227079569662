import {observer} from 'mobx-react';
import {ListItemAvatar} from '@mui/material';
import * as React from 'react';
import {StickyNote2} from '@mui/icons-material';
import ListEntryIcon from "../ListEntryIcon";

function AvatarTextNote({small=false}) {

  const smallStyle = small ? {width: 18, height: 18} : {};

  return (
    <ListItemAvatar>
      <ListEntryIcon small={small}>
        <StickyNote2 style={smallStyle}/>
      </ListEntryIcon>
    </ListItemAvatar>
  )
}

export default observer(AvatarTextNote);




