import * as React from 'react';
import { useApplication } from '../providers/ApplicationProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import PersonIcon from '@mui/icons-material/Person';
import useFeature from '../hooks/useFeature';
import DashboardFeature from '../features/DashboardFeature';
import { observer } from 'mobx-react';
import Dashboard from '../components/Dashboard';
import SettingsIcon from "@mui/icons-material/Settings";


function PatientDashboardScreen() {
  const application = useApplication();
  const navigate = useNavigate();
  const location = useLocation();
  const sections = [
    {title: 'Inicio', icon: <BookmarkBorderIcon/>, path: '/'},
    {title: 'Perfil', icon: <PersonIcon/>, path: 'profile'},
    {title: 'Mi legajo', icon: <StickyNote2Icon/>, path: 'notes'},
    {title: 'Configuración', icon: <SettingsIcon/>, path: 'settings'},
  ];
  const feature = useFeature(() =>
    new DashboardFeature(application, navigate, location, sections), [location]);

  return <Dashboard feature={feature}/>;
}


export default observer(PatientDashboardScreen);