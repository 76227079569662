export class User {

  constructor({fullName, email, role}) {
    this._fullName = fullName;
    this._email = email;
    this._role = role;
  }

  static newPatient({fullName, email}) {
    return new User({fullName, email, role: 'patient'});
  }

  static newDoctor({fullName, email}) {
    return new User({fullName, email, role: 'doctor'});
  }

  isDoctor() {
    return this._role === 'doctor';
  }

  isPatient() {
    return this._role === 'patient';
  }

  fullName() {
    return this._fullName;
  }

  email() {
    return this._email;
  }

  role() {
    return this._role;
  }

  isAnonymous() {
    return false;
  }
}