import {observer} from 'mobx-react';
import MaiaIconButton from '../buttons/MaiaIconButton';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import {useAudioPlayer} from '../../providers/AudioPlayerProvider';

function PlayButton({audioOwner, audioScr, size, iconColor, backgroundColor, style}) {
  const audioPlayer = useAudioPlayer();

  const isPlaying = () => audioPlayer.isAudioPlaying && audioPlayer.audioSrcPlaying === audioScr && !audioPlayer.paused

  const togglePlayPause = () => {
    if (isPlaying()) {
      audioPlayer.pause();
    } else if (audioPlayer.paused) {
      audioPlayer.resume();
    } else {
      audioPlayer.startPlaying(audioOwner, audioScr);
    }
  };
  return (
    <>
      <MaiaIconButton
        icon={isPlaying() ? Pause : PlayArrow}
        title={'Reproducir'}
        iconSize={size}
        iconColor={iconColor || 'white'}
        backgroundColor={backgroundColor}
        onClick={togglePlayPause}
        extraStyles={style}
      />
    </>
  );
}

export default observer(PlayButton);




