import {useTheme} from "@mui/material";

export default function FilledButton({children, onClick, style = {}, disabled=false, title}) {
    const theme = useTheme();

    const ownStyle = styles(theme, disabled);

    return (
        <button style={{...ownStyle.button, ...style}} onClick={onClick} disabled={disabled} title={title}>
            {children}
        </button>
    );
}

const styles = (theme, disabled) => ({
    button: {
        backgroundColor: disabled ? theme.colors.disabledButton : theme.colors.button,
        color: theme.text.primary,
        padding: '8px 16px',
        borderRadius: 8,
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});