import {useTheme} from '@mui/material';
import FormTextField from '../../components/fields/FormTextField';
import FormSelectField from '../../components/fields/FormSelectField';
import FormDateField from '../../components/fields/FormDateField';
import {observer} from 'mobx-react';
import FilledTextButton from '../../components/buttons/FilledTextButton';
import LineTextButton from '../../components/buttons/LineTextButton';
import FormAutocompleteField from '../../components/fields/FormAutocompleteField';
import * as React from 'react';
import StepsMainContainer from "../../components/medicalConsultationStepsComponents/StepsMainContainer";
import {ScreenTitleBar} from "../../components/ScreenTitleBar";
import ButtonsContainer from '../../components/medicalConsultationStepsComponents/ButtonsContainer';

function CreatePatientStep({form, onBack, onContinue}) {
  const theme = useTheme();
  const style = styles(theme);

  const newPatientForm = () => {
    return (
      <section style={style.formContainer}>
        <div style={style.rowContainer}>
          <FormTextField label={'Nombre'} field={form.getFieldByName('firstName')}/>
          <FormTextField label={'Apellido'} field={form.getFieldByName('lastName')}/>
        </div>
        <div style={style.rowContainer}>
          <FormSelectField label={'Sexo'} field={form.getFieldByName('biologicalSex')}/>
          <FormDateField label={'Fecha de Nacimiento'} field={form.getFieldByName('birthdate')}/>
        </div>
        <div style={style.rowContainer}>
          <FormTextField label={'Email'} field={form.getFieldByName('email')}/>
          <FormTextField label={'DNI'} field={form.getFieldByName('identificationNumber')}/>
        </div>
        <div style={style.rowContainer}>
          <FormAutocompleteField
            extraLabelStyle={style.label}
            label="Grupo Étnico"
            field={form.getFieldByName('ethnicGroup')}
            config={form.getFieldByName('ethnicGroup').autocompleteConfig()}
          />
        </div>
      </section>
    )
  }

  return (
    <StepsMainContainer>

      <ScreenTitleBar title={'Completar datos del paciente'} showBackButton={false}/>

      {newPatientForm()}

      <ButtonsContainer>
        <FilledTextButton label="Continuar" disabled={!form.isValid} onClick={onContinue}/>
        <LineTextButton label={'Volver'} light onClick={onBack}/>
      </ButtonsContainer>
    </StepsMainContainer>
  );

}

export default observer(CreatePatientStep);

const styles = () => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '10px',
    width: '70%',
  }
});