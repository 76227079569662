import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function ErrorBox({errorIcon, errorMessage}) {
  const theme = useTheme();
  const style = styles(theme);

  const Icon = errorIcon;

  return (
    <Box style={style.errorContainer}>
      <Icon style={{height: '2em', width: '2em'}}/>
      <Typography>
        {errorMessage}
      </Typography>
    </Box>
  )
}

const styles = (theme) => ({
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: theme.padding.box,
    alignItems: 'center',
    textAlign: 'center',
    color: theme.colors.white,
    borderRadius: '0.2rem',
    borderWidth: '1px',
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
  }
});