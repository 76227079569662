import TextField from '../libs/forms/fields/TextField';
import { action, computed, makeObservable, observable } from 'mobx';
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import { toast } from 'react-toastify';

export class TextEntry {
  constructor (title, isPublic, content, creationDate, shared_id, tags, id) {
    this._title = title;
    this._isPublic = isPublic;
    this._content = content;
    this._creation_date = creationDate;
    this._id = id;
    this._tags = tags;
    this._shared_id = shared_id;

    this._titleField = new TextField();
    this._newContentField = new TextField();
    this._titleField.updateValue(title);
    this._newContentField.updateValue(content);

    makeObservable(this, {
      _title: observable,
      _isPublic: observable,
      _content: observable,
      title: computed,
      content: computed,
      save: action,
      setVisibility: action,
    });
  }

  save (apiClient) {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
        this._content = this._newContentField.value;
        this._title = this._titleField.value;
        toast.success('Nota de texto editada exitosamente!');
      }
    });
    apiClient.editTextNoteContent(this.shared_id, this._titleField.value, this._newContentField.value, responseHandler);
  }

  update (content) {
    this._newContentField.updateValue(content);
  }

  setVisibility (newValue) {
    this._isPublic = newValue;
  }

  get title () {
    return this._title;
  }

  get isPublic () {
    return this._isPublic;
  }

  get tags () {
    return this._tags;
  }

  get content () {
    return this._content;
  }

  set content (content) {
    this._content = content;
  }

  get creationDate () {
    return this._creation_date;
  }

  get id () {
    return this._id;
  }

  get shared_id () {
    return this._shared_id;
  }

  get field () {
    return this._newContentField;
  }

  get titleField () {
    return this._titleField;
  }

  get isAudio () {
    return false;
  }

  get isText () {
    return true;
  }
}