import { FileEntry } from '../../../domain/FileEntry';

export default class FileAdapter {
  constructor(file) {
    this._file = file;
  }

  adapt() {
    return new FileEntry({
      id: this._file.path,
      shared_id: this._file.shared_id,
      name: this._file.title,
      title: this._file.title,
      isPublic: this._file.public,
      publicUrl: this._file.public_url,
      path: this._file.path,
      creationDate: this._file.creation_date,
      isFile: true,
      isAnImage: this._file.is_an_image,
      tags: this._file.tags,
    });
  }
}