import React from 'react';
import { useApplication } from './ApplicationProvider';
import useWebSocket from 'react-use-websocket';

export const NotificationReceiverContext = React.createContext(null);

const WebsocketNotificationProvider = ({children}) => {
  const application = useApplication();
  const notificationReceiver = application.notificationReceiver();

  useWebSocket(application.websocketUrl(), {
    onMessage: (event) => { notificationReceiver.messageReceived(event.data) },
    heartbeat: true,
    shouldReconnect: () => true
  });

  return (
    <NotificationReceiverContext.Provider value={notificationReceiver}>
      {children}
    </NotificationReceiverContext.Provider>
  );
}

export const NotificationReceiverProvider = ({ children }) => {
  const application = useApplication();

  return !application.userIsLoggedIn ? children : <WebsocketNotificationProvider>{children}</WebsocketNotificationProvider>;
};
