export class AnonymousUser {

  fullName() {
    return "";
  }

  role() {
    return "";
  }

  isDoctor() {
    return false;
  }

  isPatient() {
    return false;
  }

  isAnonymous() {
    return true;
  }
}