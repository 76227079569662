import Typography from "@mui/material/Typography";
import {useApplication} from '../providers/ApplicationProvider';
import {useTheme} from "@mui/material";
import {observer} from 'mobx-react';
import PatientHomeFeature from "../features/PatientHomeFeature";
import useFeature from "../hooks/useFeature";
import {useLocation, useNavigate} from "react-router-dom";
import DashboardMainContainer from "../components/DashboardMainContainer";

function PatientHomeScreen() {
  const theme = useTheme();
  const application = useApplication();
  const navigate = useNavigate();
  const location = useLocation();
  const isComingFromLogIn = location.state && location.state.isComingFromLogIn;
  const feature = useFeature(() => new PatientHomeFeature(application, navigate, isComingFromLogIn));

  return feature && (
    <DashboardMainContainer>
      <section>
        <Typography color={theme.text.primary} variant="h5" fontWeight={'800'}>
          👋 Buenos días {application.user.fullName()}!
        </Typography>
        <Typography color={theme.text.primary} variant="h6">
          Tené un gran gran día.
        </Typography>
      </section>
    </DashboardMainContainer>
  );
}

export default observer(PatientHomeScreen);