import React, {useEffect, useState} from 'react';
import {useApplication} from "./ApplicationProvider";
import VoiceAudioRecorder from "../app/VoiceAudioRecorder";
import useBrowserAudioRecorder from "../hooks/useBrowserAudioRecorder";

export const VoiceAudioRecorderContext = React.createContext(null);

export const VoiceAudioRecorderProvider = ({children}) => {
  const application = useApplication();
  const [voiceAudioRecorder, setVoiceAudioRecorder] = useState(null);

  const browserAudioRecorder = useBrowserAudioRecorder({
    onRecordingStopped: (recordingBlob) => {
      voiceAudioRecorder.saveRecording(recordingBlob);
    },
    onRecordingCanceled: () => {
      voiceAudioRecorder.cancelRecording();
    }
  })

  useEffect(() => {
    if (!browserAudioRecorder) {
      return;
    }

    if (voiceAudioRecorder) {
      voiceAudioRecorder.setBrowserAudioRecorder(browserAudioRecorder);
    } else {
      const newVoiceAudioRecorder = new VoiceAudioRecorder(application, browserAudioRecorder);
      setVoiceAudioRecorder(newVoiceAudioRecorder);
    }
  }, [browserAudioRecorder]);

  return voiceAudioRecorder && (
    <VoiceAudioRecorderContext.Provider value={voiceAudioRecorder}>
      {children}
    </VoiceAudioRecorderContext.Provider>
  );

};

export const useVoiceAudioRecorder = () => React.useContext(VoiceAudioRecorderContext);