import * as React from "react";
import ButtonDialog from "../dialogs/ButtonDialog";
import ShareIcon from "@mui/icons-material/Share";
import {useTheme} from "@mui/material";
import {observer} from "mobx-react";
import WizardComponent from "../../libs/wizards/WizardComponent";
import SelectDoctorAndTimeStep from "./steps/SelectDoctorAndTimeStep";
import ConfirmDoctorSelectedStep from "./steps/ConfirmDoctorSelectedStep";
import SharingSuccessfulStep from "./steps/SharingSuccessfulStep";


function ShareNotebookDialog({wizard, onOpen}) {
  const theme = useTheme();
  const style = styles(theme);

  return (
    <ButtonDialog
      icon={ShareIcon}
      onOpen={onOpen}
      iconColor={theme.colors.white}
      iconHoverTitle={'Compartir legajo'}
      acceptLabel={'Eliminar'}
      title={'Compartir legajo'}
    >
      <WizardComponent steps={[SelectDoctorAndTimeStep, ConfirmDoctorSelectedStep, SharingSuccessfulStep]}
                       wizard={wizard}
                       mainContainerStyle={style.mainContainer}/>
    </ButtonDialog>
  )
}

export default observer(ShareNotebookDialog);

const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'flex-start',
    minHeight: '200px',
  }
});