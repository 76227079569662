import {action, computed, makeObservable, observable} from "mobx";
import {ChatbotBase} from "./ChatbotBase";

export class ChatbotMedicalRecord extends ChatbotBase {
  constructor(apiClient, saveConversation, patientId, onConversationSaved = undefined) {
    super(apiClient, saveConversation, onConversationSaved);
    this._entriesUsed = [];
    this._patientId = patientId;

    makeObservable(this, {
      _entriesUsed: observable,
      hasEntriesUsed: computed,
      amountOfEntriesUsed: computed,
      addEntryUsed: action,
      removeEntryFromChat: action,
    });

  }

  static newFor(apiClient, patientId, onConversationSaved) {
    const saveConversation = async (title, content, responseHandler) => {
      await apiClient.createMedicalRecordTextNote(patientId, title, content, responseHandler)
    }
    return new ChatbotMedicalRecord(apiClient, saveConversation, patientId, onConversationSaved);
  }

  _ask(question, responseHandler) {
    if (this.hasEntriesUsed) {
      const entriesIds = this._entriesUsed.map((entry) => entry.shared_id);
      return this._apiClient.askChatbotForEntry(this._patientId, entriesIds, question, responseHandler);
    } else {
      this._apiClient.askChatbotForPatient(this._patientId, question, responseHandler);
    }
  }

  get hasEntriesUsed() {
    return this.amountOfEntriesUsed > 0;
  }

  get entriesUsed() {
    return this._entriesUsed;
  }

  get amountOfEntriesUsed() {
    return this._entriesUsed.length;
  }

  addEntryUsed(entry) {
    this._entriesUsed.push(entry);
    this._entriesUsed = [...new Set(this._entriesUsed)];
  }

  removeEntryFromChat(entry) {
    this._entriesUsed = this._entriesUsed.filter((usedEntry) => usedEntry.title !== entry.title);
  }
}