import Dashboard from '../components/Dashboard';
import useFeature from '../hooks/useFeature';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApplication } from '../providers/ApplicationProvider';
import DashboardFeature from '../features/DashboardFeature';
import { observer } from 'mobx-react';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SettingsIcon from '@mui/icons-material/Settings';

function DoctorDashboardScreen () {
  const application = useApplication();
  const navigate = useNavigate();
  const location = useLocation();
  const sections = [
    {title: 'Inicio', icon: <BookmarkBorderIcon/>, path: '/'},
    {title: 'Pacientes', icon: <PeopleOutlineIcon/>, path: 'patients'},
    {title: 'Notas', icon: <StickyNote2Icon/>, path: 'notes'},
    {title: 'Configuración', icon: <SettingsIcon/>, path: 'settings'},
  ];
  const feature = useFeature(() =>
    new DashboardFeature(application, navigate, location, sections), [location]);

  return <Dashboard feature={feature}/>;
}

export default observer(DoctorDashboardScreen);