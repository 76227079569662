import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import AudioDuration from './AudioDuration';
import Markdown from 'react-markdown';
import { parseDate } from '../libs/utils';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Link } from 'react-router-dom';
import TagsEditionBox from './TagsEditionBox';
import { observer } from 'mobx-react';

function ReadOnlyNotebookEntryListItem ({
  avatarIcon,
  linkToNote,
  note,
  showBadges,
  linkShareInfo,
  text,
  isAFile = false,
  isAnAudioNote = false
}) {
  const style = styles();

  const renderName = () => {
    return (
      <Typography
        variant="body1"
        color="text.primary"
      >
        {note.title}
      </Typography>
    );
  };

  const listItem = () => {
    return (
      <ListItem
        alignItems="flex-start"
      >
        {avatarIcon}
        <ListItemText
          primary={
            <Box sx={style.headerContainer}>
              {renderName()}
              {showBadges?.()}
            </Box>
          }
          secondary={
            <Box sx={style.subtitleContainer}>
              <Box sx={style.subtitleDate}>
                <Box sx={style.rowContainer}>
                  <CalendarMonthIcon/><Typography>{parseDate(note.creationDate)}</Typography>
                </Box>
                {isAnAudioNote && note.publicUrl && <Box sx={style.rowContainer}>
                  <TimelapseIcon/><AudioDuration publicUrl={note.publicUrl} showLabel={false}/>
                </Box>
                }
              </Box>
              <TagsEditionBox note={note} editable={false}/>
              <Box sx={style.rowContainer}>
                <Typography sx={style.markdownText}>
                  <Markdown>
                    {text}
                  </Markdown>
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItem>
    );
  };

  if (isAFile) {
    return <a
      style={style.link}
      href={note.publicUrl}
      target="_blank"
      rel={'noreferrer'}>
      {listItem()}
    </a>;
  }

  return (
    <Link style={style.link} to={linkToNote}
          state={linkShareInfo}>
      {listItem()}
    </Link>
  );
}

export default observer(ReadOnlyNotebookEntryListItem);

const styles = () => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '10px'
  },
  rowContainer: {
    display: 'flex',
    gap: '5px'
  },
  markdownText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  titleInput: {
    background: 'none',
    border: 'none',
    fontSize: '1rem'
  },
  subtitleContainer: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column'
  },
  subtitleDate: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap'
  },
  link: {
    display: 'flex',
    marginRight: '10px',
    textDecoration: 'none'
  }
});