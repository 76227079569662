import { action, computed, makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';

export default class AudioPlayer {
  constructor (voiceAudioRecorder) {
    this._audioSrcPlaying = null;
    this._audioOwner = null;
    this._paused = false;
    this._audioElement = null;
    this._audioProgress = 0;
    this._voiceAudioRecorder = voiceAudioRecorder;
    this._voiceAudioRecorder.useAudioPlayer(this);

    makeObservable(this, {
      _audioProgress: observable,
      _paused: observable,
      _audioSrcPlaying: observable,
      _audioOwner: observable,
      isAudioPlaying: computed,
      audioOwner: computed,
      startPlaying: action,
      close: action,
      pause: action,
      resume: action,
      updateProgress: action,
      onPause: action,
      onPlay: action,
    });
  }

  resume() {
    if(this.paused) {
      this._paused = false;
      this._audioElement.play();
    }
  }

  startPlaying (patient, audioSrc) {
    if(this._voiceAudioRecorder.isRecording) {
      toast.info("Estás grabando a otro paciente. Detén la grabación para escuchar una nota.", {position: "top-right"});
      return;
    }

    this._audioSrcPlaying = audioSrc;
    this._audioOwner = patient;
    this._paused = false;
  }

  pause () {
    this._paused = true;
    this._audioElement.pause();
  }

  close = () => {
    this._audioElement.pause();
    this._audioElement.currentTime = 0;
    this._audioSrcPlaying = null;
    this._audioOwner = null;
    this._paused = false;
    this._audioProgress = 0;
  }

  updateProgress = () => {
    const duration = this._audioElement.duration;
    const currentTime = this._audioElement.currentTime;
    this._audioProgress = (currentTime / duration) * 100;
  }

  setAudioElement (audioElement) {
    this._audioElement = audioElement;

    this._audioElement.addEventListener('play', this.onPlay);
    this._audioElement.addEventListener('pause', this.onPause);
    this._audioElement.addEventListener('timeupdate', this.updateProgress);
    this._audioElement.addEventListener('ended', this.close);
  }

  removeAudioElement () {
    this._audioElement.removeEventListener('play', this.onPlay);
    this._audioElement.removeEventListener('pause', this.onPause);
    this._audioElement.removeEventListener('timeupdate', this.updateProgress);
    this._audioElement.removeEventListener('ended', this.close);
    this._audioElement = null;
  }

  get isAudioPlaying () {
    return !!this._audioSrcPlaying;
  }

  get audioOwner () {
    return this._audioOwner;
  }

  get audioSrcPlaying () {
    return this._audioSrcPlaying;
  }

  get paused () {
    return this._paused;
  }

  get audioProgress () {
    return this._audioProgress;
  }

  onPause = () => {
    this._paused = true;
  }

  onPlay = () => {
    this._paused = false;
  }
}