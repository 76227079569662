import { useTheme } from '@mui/material';
import { useApplication } from '../providers/ApplicationProvider';
import { useLocation } from 'react-router-dom';
import useFeature from '../hooks/useFeature';
import SeeAudioNoteFeature from '../features/SeeAudioNoteFeature';
import { observer } from 'mobx-react';
import { ScreenTitleBar } from '../components/ScreenTitleBar';
import * as React from 'react';
import AudioNoteView from '../components/AudioNoteView';

function SeeAudioNoteScreen() {
  const theme = useTheme();
  const application = useApplication();
  const location = useLocation();
  const id = location.state.audioEntryId;
  const owner = location.state.owner;
  const feature = useFeature(() => new SeeAudioNoteFeature(id, application), [location]);

  const style = styles(theme);

  return feature && (
    <section style={style.mainContainer}>
      <ScreenTitleBar title={feature.notebookEntry.title}/>
      <AudioNoteView
        audioNote={feature.notebookEntry}
        audioOwner={owner}
        audioNoteTranscription={feature.audioNoteTranscription}
        audioNoteTranscriptionDown={feature.audioNoteTranscriptionDown}
      />
    </section>
  );
}

export default observer(SeeAudioNoteScreen);

const styles = (theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection:
      'column',
    gap:
      '2rem',
  },
  audioInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.padding.box,
    gap: '1rem',
    backgroundColor: theme.colors.lightBackground,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8rem',
  },
  resumeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  }
});