import ApiResponseHandler from "@eryxcoop/appyx-comm/src/errors/ApiResponseHandler";
import {action, computed, makeObservable, observable} from "mobx";
import SeeNotebookEntryFeature from './SeeNotebookEntryFeature';

export default class SeeAudioNoteFeature extends SeeNotebookEntryFeature {
  constructor(id, application) {
    super(id, application);

    this._audioNoteTranscription = undefined;
    this._audioNote = {};
    this._audioNoteTranscriptionDown = false;

    makeObservable(this, {
      _audioNoteTranscription: observable,
      _audioNoteTranscriptionDown: observable,
      audioNoteTranscription: computed,
      audioNoteTranscriptionDown: computed,
      getAudioNoteTranscription: action,
    });
  }

  load() {
    this.getAudioNoteTranscription();
    super.load();
  }

  getAudioNoteTranscription() {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._audioNoteTranscription = response.transcription();
      },
      handlesError: (response) => {
        if (response.errors()[0].code === "retry_error") {
          this._audioNoteTranscriptionDown = true;
        }
      }
    });
    this._application.apiClient().getAudioNoteTranscription(this._id, responseHandler);
  }

  get audioNoteTranscription() {
    return this._audioNoteTranscription;
  }

  get audioNoteTranscriptionDown() {
    return this._audioNoteTranscriptionDown;
  }
}