import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import MaiaIconButton from "../buttons/MaiaIconButton";
import FilledTextButton from "../buttons/FilledTextButton";
import LineTextButton from "../buttons/LineTextButton";

export default function ValidateActionButtonDialog({
                                                     icon,
                                                     iconColor,
                                                     iconHoverTitle,
                                                     onConfirm,
                                                     acceptLabel,
                                                     title,
                                                     extraButtonStyles = {},
                                                     subText
                                                   }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  }

  const handleAccept = (e) => {
    e.preventDefault();
    onConfirm();
    setOpen(false);
  }

  return (
    <>
      <MaiaIconButton icon={icon}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpen(true);
                      }}
                      extraStyles={extraButtonStyles}
                      iconColor={iconColor}
                      title={iconHoverTitle}/>
      <Dialog
        open={open}
        bodyStyle={{padding: theme.spacing(2), zIndex: 1000}}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LineTextButton light={false} label="Cancelar" onClick={handleClose}/>
          <FilledTextButton label={acceptLabel} onClick={handleAccept} autoFocus/>
        </DialogActions>
      </Dialog>
    </>
  )
}