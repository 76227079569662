import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import ListOrNoElements from './ListOrNoElements';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import ReadOnlyNotebookEntryListItem from './ReadOnlyNotebookEntryListItem';
import AvatarPlayButton from './audio/AvatarPlayButton';
import AvatarTextNote from './audio/AvatarTextNote';
import { FileAvatarIcon } from './FileAvatarIcon';

export default function SharedNotebook ({
  patientId,
  patient,
  notes,
  audioNotesOwner,
  isRetrievingData,
  listStyles
}) {
  const theme = useTheme();
  const style = styles(theme);

  const renderListElement = (note, index) => {
    const linkToEntry = `/patients/${patientId}/shared_entry/${note.shared_id}`;
    const patientInfo = {
      first_name: patient.first_name,
      last_name: patient.last_name,
      shared_id: patient.shared_id
    };
    return (
      <Box key={`note-${index}`}>
        {note.isAudio &&
          <ReadOnlyNotebookEntryListItem
            avatarIcon={<AvatarPlayButton audioScr={note.publicUrl} audioOwner={audioNotesOwner}/>}
            linkToNote={linkToEntry}
            linkShareInfo={{ patientInfo }}
            note={note}
            isAFile={false}
            isAnAudioNote={true}
          />
        }
        {note.isText && <ReadOnlyNotebookEntryListItem
          avatarIcon={<AvatarTextNote/>}
          linkToNote={linkToEntry}
          note={note}
          text={note.content}
          isAFile={false}
          linkShareInfo={{ patientInfo }}
        />}
        {note.isFile && (
          <ReadOnlyNotebookEntryListItem
            avatarIcon={<FileAvatarIcon note={note}/>}
            linkToNote={linkToEntry}
            note={note}
            isAFile={!note.isAnImage}
            text={note.summaryContent}
            linkShareInfo={{ patientInfo }}
          />)}
        {index < notes.length - 1 && <Divider component="li"/>}
      </Box>
    );
  };

  return (
    <ListOrNoElements
      noElementIcon={PublicOffIcon}
      elements={notes}
      isRetrievingData={isRetrievingData}
      listStyle={{ ...style.notesList, ...listStyles }}
      renderElement={renderListElement}
      noElementMessage={'El paciente no está compartiendo su legajo'}
    />
  );
}

const styles = (theme) => ({
  notesList: {
    width: '100%',
    backgroundColor: theme.colors.white,
    borderRadius: '5px',
    gap: '4px'
  },
  noElementsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.padding.box,
    gap: '1rem',
    borderRadius: '5px',
    color: theme.colors.white,
    backgroundColor: theme.colors.lightBackground,
    border: `1px solid ${theme.colors.gray}`,
    width: '100%',
  }
});