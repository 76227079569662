import FilledButton from "./FilledButton";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function FilledTextButton({onClick, style = {}, disabled = false, title, label}) {

  return (
    <FilledButton
      title={title}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography>
        {label}
      </Typography>
    </FilledButton>
  );
}