import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { parseDate } from '../libs/utils';
import * as React from 'react';

export function ImageNoteView ({ imageNote }) {
  const theme = useTheme();
  const style = styles();

  return <>
    <Typography color={theme.text.primary} variant="body1">
      Fecha de carga: {parseDate(imageNote.creationDate)}
    </Typography>
    <img style={style.image} src={imageNote.publicUrl} alt="Imagen"/>
  </>;
}

const styles = () => ({
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '800px',
  }
});