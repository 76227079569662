import {action, makeObservable} from "mobx";
import {toast} from "react-toastify";

export default class DoctorLoginFeature {
  constructor(application, navigate) {
    this._application = application;
    this._navigate = navigate;

    makeObservable(this, {
      loginDoctor: action,
    });
  }

  loginDoctor = async () => {
    const onError = () => {
      toast.error("Error al iniciar sesión");
    }
    const onSuccess = () => {
      this._navigate("/");
    }
    await this._application.logInDoctor(onError, onSuccess);
  }
}