import Box from "@mui/material/Box";
import FormTextAreaField from "./fields/FormTextAreaField";
import * as React from "react";
import {useState} from "react";
import {observer} from "mobx-react";
import FormTextField from './fields/FormTextField';
import FilledTextButton from "./buttons/FilledTextButton";
import LineTextButton from "./buttons/LineTextButton";
import MaiaLinearProgress from "./loaders/MaiaLinearProgress";

function CreateTextNote({feature}) {
  const style = styles();
  const [uploadingNote, setUploadingNote] = useState(false);

  const onTextNoteSave = async () => {
    await setUploadingNote(true);
    await feature.createTextNote();
    await setUploadingNote(false);
  }

  return (
    <Box style={style.boxContainer}>
      {uploadingNote && <MaiaLinearProgress/>}
      <FormTextField field={feature.textNoteForm.getFieldByName('title')}
                     disabled={uploadingNote}
                     label={'Título'}/>
      <FormTextAreaField field={feature.textNoteForm.getFieldByName('textNote')}
                         disabled={uploadingNote}
                         label={'Contenido'}/>
      <Box sx={style.buttonsContainer}>
        <LineTextButton label={"Cancelar"} onClick={feature.cancelTextNote} light={true} disabled={uploadingNote}/>
        <FilledTextButton label={"Guardar"} disabled={uploadingNote || !feature.textNoteForm.isValid} onClick={onTextNoteSave}/>
      </Box>
    </Box>
  );
}

export default observer(CreateTextNote);

const styles = () => ({
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  buttonsContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
  },
});