import Box from "@mui/material/Box";
import MaiaIconButton from "./buttons/MaiaIconButton";
import CloseIcon from '@mui/icons-material/Close';
import * as React from "react";
import Typography from "@mui/material/Typography";

export default function TagChip({tag, onDelete}) {
  const style = styles();

  const renderName = () => {
    return (
      <Typography
        variant="body2"
        color="text.primary"
        style={{zIndex: 2}}
      >
        {tag.name}
      </Typography>
    )
  }

  return (
    <Box key={tag.id} sx={style.tagContainer}>
      {renderName()}
      {onDelete && <MaiaIconButton icon={CloseIcon}
                                   title={'Cancel'}
                                   iconSize={'1rem'}
                                   onClick={() => onDelete(tag)}/>}
    </Box>
  )

}

const styles = () => ({
  tagContainer: {
    backgroundColor: '#ffd05c',
    borderRadius: '2px',
    padding: '0.1rem 0.4rem',
    display: 'flex',
    alignItems: 'space-between',
    width: 'fit-content',
    gap: '0.4rem',
  },
});