import {observer} from 'mobx-react';
import FormTextField from '../../components/fields/FormTextField';
import {ScreenTitleBar} from '../../components/ScreenTitleBar';
import FilledTextButton from "../../components/buttons/FilledTextButton";
import ButtonsContainer from "../../components/medicalConsultationStepsComponents/ButtonsContainer";
import * as React from "react";
import StepsMainContainer from "../../components/medicalConsultationStepsComponents/StepsMainContainer";

function PatientIdStep({formField, onContinue}) {
  return (
    <StepsMainContainer>
      <ScreenTitleBar title={'Ingresa DNI'}/>

      <FormTextField field={formField} label={"DNI"} width={'50%'}/>

      <ButtonsContainer>
        <FilledTextButton label="Continuar" disabled={!formField.isValid} onClick={onContinue}/>
      </ButtonsContainer>
    </StepsMainContainer>
  );
}


export default observer(PatientIdStep);
