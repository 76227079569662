import { ScreenTitleBar } from '../components/ScreenTitleBar';
import * as React from 'react';
import DashboardMainContainer from '../components/DashboardMainContainer';
import { ImageNoteView } from '../components/ImageNoteView';
import useFeature from '../hooks/useFeature';
import { useParams } from 'react-router-dom';
import { useApplication } from '../providers/ApplicationProvider';
import { observer } from 'mobx-react';
import SeeNotebookEntryFeature from '../features/SeeNotebookEntryFeature';

function SeeImageFileScreen() {
  const application = useApplication();
  const {entryId} = useParams();
  const feature = useFeature(() => new SeeNotebookEntryFeature(entryId, application), []);

  return feature && (
    <DashboardMainContainer>
      <ScreenTitleBar title={feature.notebookEntry.title || 'Imagen'}/>
      <ImageNoteView
        imageNote={{creationDate: feature.notebookEntry.creationDate, publicUrl: feature.notebookEntry.publicUrl}}/>
    </DashboardMainContainer>
  )
}

export default observer(SeeImageFileScreen);
