import * as React from "react";
import Box from "@mui/material/Box";
import FormTextField from "../fields/FormTextField";
import {observer} from "mobx-react";
import {useTheme} from "@mui/material";
import TextField from "../../libs/forms/fields/TextField";
import MaiaFilledIconButton from "../buttons/MaiaFilledIconButton";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SearchIcon from '@mui/icons-material/Search';

function ChatBotSendMessageBar({onSearch, placeholder, searchDisabled = false, showDocumentSearch}) {
  const theme = useTheme();
  const queryField = new TextField(2, 100);
  const style = styles(theme);

  const submitSearch = () => {
    if (queryField.isValid) {
      return onSearch(queryField.value);
    }
  }

  return (
    <Box sx={style.queryBoxContainer}>
      <FormTextField
        field={queryField}
        placeholder={placeholder}
        disabled={searchDisabled}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitSearch();
          }
        }}
      />
      <MaiaFilledIconButton icon={SearchIcon}
                            title={'Preguntar'}
                            disabled={searchDisabled}
                            onClick={() => submitSearch()}/>
      {
        showDocumentSearch ?
          <MaiaFilledIconButton icon={ContentPasteSearchIcon}
                                title={'Obtener documentos'}
                                disabled={searchDisabled}
                                onClick={() => console.log('Not done')}/>
          :
          <></>

      }
    </Box>
  );
}

export default observer(ChatBotSendMessageBar);

const styles = (theme) => ({
  queryBoxContainer: {
    backgroundColor: theme.colors.lightBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
});