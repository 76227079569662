import {TextEntry} from "../../../domain/TextEntry";


export default class TextNoteAdapter {
  constructor(textNote) {
    this._textNote = textNote;
  }

  adapt() {
    return new TextEntry(
      this._textNote.title,
      this._textNote.public,
      this._textNote.content,
      this._textNote.creation_date,
      this._textNote.shared_id,
      this._textNote.tags,
      this._textNote.id,
    );
  }
}