import MaiaFilledIconButton from "../buttons/MaiaFilledIconButton";
import FaceIcon from "@mui/icons-material/Face";
import "../../styles/ShakeAnimation.css"
import * as React from "react";

export default function ChatButton({onClick}) {
  const style = styles();

  return (
    <MaiaFilledIconButton icon={FaceIcon}
                          title={'Chat'}
                          iconClassName={'chat-icon'}
                          onClick={onClick}
                          extraStyles={style.chatOpenButton}/>
  )
}

const styles = () => ({
    chatOpenButton: {
      position: 'fixed',
      right: '10px',
      bottom: '10px'
    }
  })
;