import {Chip} from "@mui/material";

export default function MaiaChip({label, color, variant}) {
  return <Chip sx={styles.chip} label={label} color={color}
               variant={variant}/>
}

const styles = {
  chip: {
    borderRadius: '5px',
    padding: '2px 4px',
    height: '20px',
    fontSize: '12px',
  },
}