import {TextField, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import FormFieldBase from './FormFieldBase';

function FormTextField({
                         label = undefined,
                         placeholder = undefined,
                         field,
                         extraLabelStyle = {},
                         width = '100%',
                         disabled = false,
                         onChange = () => {
                         },
                         rows = 1,
                         ...props
                       }) {
  const theme = useTheme();
  const style = styles(theme, width);

  return (
    <FormFieldBase label={label} field={field} extraLabelStyle={extraLabelStyle}>
      <TextField
        placeholder={placeholder || label}
        InputLabelProps={{shrink: false}}
        disabled={disabled}
        multiline={rows > 1}
        rows={rows}
        error={field.hasErrors}
        style={style.fieldContainer}
        value={field.value}
        size="small"
        onBlur={() => {
          field.validate();
        }}
        onChange={(e) => {
          field.updateValue(e.target.value);
          onChange(e.target.value);
        }}
        {...props}
      />
    </FormFieldBase>
  );
}

const styles = (theme, width) => ({
  fieldContainer: {
    backgroundColor: theme.fields.background,
    justifyContent: 'center',
    borderRadius: '5px',
    width: width,
  }
});

export default observer(FormTextField);