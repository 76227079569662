import {useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MaiaIconButton from "../buttons/MaiaIconButton";
import CloseIcon from "@mui/icons-material/Close";
import MaiaAccordion from "../MaiaAccordion";
import {pluralize} from "../../libs/utils";
import * as React from "react";
import {observer} from "mobx-react";

function EntriesUsedAccordion({chatBot}) {
  const theme = useTheme();
  const style = styles(theme);

  const renderElement = (element) => {
    return (
      <Box style={style.showEntryBox} key={element.title}>
        <Typography variant="body1" color={'white'}>
          {element.title}
        </Typography>
        <MaiaIconButton icon={CloseIcon}
                        title={'Cancel'}
                        iconSize={'1.2rem'}
                        iconColor={theme.palette.violet.dark}
                        onClick={() => chatBot.removeEntryFromChat(element)}/>
      </Box>
    );
  }
  return (
    chatBot.hasEntriesUsed &&
    <MaiaAccordion
      styles={{backgroundColor: theme.palette.lilac.dark, color: 'white'}}
      elements={chatBot.entriesUsed}
      renderElement={renderElement}>
      <b>Utilizando {pluralize('nota', chatBot.amountOfEntriesUsed)}:</b>
    </MaiaAccordion>
  );
}

export default observer(EntriesUsedAccordion);

const styles = (theme) => ({
    showEntryBox: {
      borderColor: theme.palette.lilac.dark,
      padding: '3px',
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
;