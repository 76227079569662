import {SuccessfulApiResponse} from "@eryxcoop/appyx-comm";
import TextNoteAdapter from "../adapters/TextNoteAdapter";

class TranscriptionAdapter {
  constructor(transcription) {
    this.transcription = transcription;
  }

  adapt() {
    return {
      text: this.transcription.text,
      status: this.transcription.status,
      summary: this.transcription.summary ? new TextNoteAdapter(this.transcription.summary).adapt() : null
    }
  }
}

export default class GetAudioNoteTranscriptionResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        transcription: {
          "text": "This is a transcription",
          "status": {
            "started": true,
            "failed": false
          },
          "summary": {
            "id": "123",
            "content": "This is a resume",
            "creation_date": "2021-01-01"
          }
        }
      }
    }
  }

  transcription() {
    return new TranscriptionAdapter(this.content().transcription).adapt();
  }
}