import {useTheme} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import {observer} from "mobx-react";
import Typography from "@mui/material/Typography";
import {useVoiceAudioRecorder} from "../../providers/VoiceAudioRecorderProvider";
import MaiaFilledIconButton from "../buttons/MaiaFilledIconButton";

function RecordButton({person}) {
  const theme = useTheme();
  const voiceAudioRecorder = useVoiceAudioRecorder();
  const style = styles(theme);

  // TODO: Change
  const isRecordingPerson = voiceAudioRecorder.personBeingRecord !== null && voiceAudioRecorder.personBeingRecord.identification_number === person.identification_number;

  const stopRecordButton = () => {
    return (
      <div style={style.recordingContainer}>
        <Typography fontWeight={'800'}>
          Grabando...
        </Typography>
        <MaiaFilledIconButton icon={StopIcon}
                              title={'Detener grabación'}
                              disabled={!isRecordingPerson}
                              onClick={() => voiceAudioRecorder.stopRecording()}/>
      </div>
    )
  }

  if (voiceAudioRecorder.isRecording) {
    return stopRecordButton();
  } else {
    return (
      <MaiaFilledIconButton icon={KeyboardVoiceIcon}
                            title={'Grabar audio'}
                            onClick={() => voiceAudioRecorder.startRecording(person)}/>
    )
  }
}


export default observer(RecordButton);

const styles = (theme) => ({
  recordingContainer: {
    alignItems: 'center',
    display: 'flex',
    background: theme.alerts.success,
    borderRadius: '5px',
    gap: '15px',
    paddingLeft: '15px',
    marginLeft: 'auto'
  }
});