import {useTheme} from '@mui/material';
import {useApplication} from '../../providers/ApplicationProvider';
import DoctorLoginFeature from '../../features/logins/DoctorLoginFeature';
import {observer} from "mobx-react";
import LoginBox from "../../components/LoginBox";
import {useNavigate} from "react-router-dom";

function DoctorLoginScreen() {
  const theme = useTheme();
  const app = useApplication();
  const navigate = useNavigate();
  const feature = new DoctorLoginFeature(app, navigate);
  const style = styles(theme);

  return (
    <main style={style.mainContainer}>
      <LoginBox login={feature.loginDoctor}
                pathToOtherLogin={'/login/patient'}
                subtitleOtherLoginLink={"¿Eres paciente? Inicia sesión aquí."}
                subtitle={"doctores"}/>
    </main>
  );
}

export default observer(DoctorLoginScreen);

const styles = (theme) => ({
  mainContainer: {
    backgroundColor: theme.colors.background,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginBoxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.colors.darkGrey,
    minWidth: 300,
    minHeight: 300,
    padding: '2rem',
  }
});