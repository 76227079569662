import Box from "@mui/material/Box";

export default function DashboardMainContainer({children}) {
  const style = styles();
  return (
    <Box sx={style.mainContainer}>
      {children}
    </Box>
  )
}

const styles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }
});