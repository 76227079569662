import {useTheme} from "@mui/material";


export default function LineButton({onClick, children, fullWidth = false, light = false, extraStyles = {}, disabled=false}) {
  const theme = useTheme();
  const style = styles(theme, fullWidth, light);


  return (
    <button style={{...style.button, ...extraStyles}} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}

const styles = (theme, fullWidth, light) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    color: light ? theme.text.primary : theme.text.secondary,
    background: 'none',
    border: '1px solid',
    borderColor: theme.colors.darkGrey,
    width: fullWidth ? '100%' : 'auto',
    padding: '8px 16px',
    borderRadius: 8,
    cursor: 'pointer',
  },
});