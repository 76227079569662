import MaiaFilledIconButton from './buttons/MaiaFilledIconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as React from 'react';

export function UploadFileButton ({ onClick }) {
  return <MaiaFilledIconButton
    icon={UploadFileIcon}
    title={'Subir archivo'}
    onClick={onClick}
  />;
}