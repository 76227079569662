import { observer } from 'mobx-react';
import FormFieldBase from './FormFieldBase';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import '../../assets/maia-markdown-editor.css';
import Box from '@mui/material/Box';

function FormTextAreaField ({
  label = undefined,
  disabled = false,
  field,
}) {
  const style = styles();

  return (
    <FormFieldBase label={label} field={field}>
      <MDXEditor
        className="maia-markdown-editor"
        contentEditableClassName="maia-markdown-editor-content"
        markdown={field.value || ''}
        readOnly={disabled}
        placeholder="Escribe tu nota..."
        onChange={(newValue) => {
          field.updateValue(newValue);
        }}
        plugins={[
          headingsPlugin(),
          listsPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <Box sx={style.toolbar}>
                <UndoRedo />
                <BlockTypeSelect/>
                <BoldItalicUnderlineToggles />
                <ListsToggle />
              </Box>
            )
          })
        ]}
      />
    </FormFieldBase>
  );
}

const styles = () => ({
  toolbar: {
    display: 'flex',
    maxWidth: '80vw'
  },
});

export default observer(FormTextAreaField);