import { action, computed, makeObservable, observable } from 'mobx';
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';

export class SeeSharedEntryFeature {
  constructor (application, patientId, entryId) {
    this._application = application;
    this._patientId = patientId;
    this._entryId = entryId;
    this._entry = undefined;
    this._audioNoteTranscription = undefined;
    this._audioNoteTranscriptionDown = false;

    makeObservable(this, {
      _entry: observable,
      _audioNoteTranscription: observable,
      _audioNoteTranscriptionDown: observable,
      _updateEntry: action,
      _updateAudioNoteTranscription: action,
      entry: computed
    });
  }

  load () {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        const notebookEntry = response.notebookEntry();
        this._updateEntry(notebookEntry);
        if (notebookEntry.isAudio) {
          this.getAudioNoteTranscription();
        }
      }
    });
    this._application.apiClient().getSharedEntry(this._patientId, this._entryId, responseHandler);
  }

  getAudioNoteTranscription () {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: (response) => {
        this._updateAudioNoteTranscription(response.transcription());
      },
      handlesError: (response) => {
        if (response.errors()[0].code === 'retry_error') {
          this._audioNoteTranscriptionDown = true;
        }
      }
    });
    this._application.apiClient().getAudioNoteTranscription(this._entryId, responseHandler);
  }

  _updateAudioNoteTranscription (audioNoteTranscription) {
    this._audioNoteTranscription = audioNoteTranscription;
  }

  _updateEntry (entry) {
    this._entry = entry;
  }

  get entry () {
    return this._entry;
  }

  get audioNoteTranscription () {
    return this._audioNoteTranscription;
  }

  get audioNoteTranscriptionDown () {
    return this._audioNoteTranscriptionDown;
  }
}