import {useTheme} from "@mui/material";

export default function Line({width=undefined, extraStyles={}}) {
    const theme = useTheme();
    const style = styles(theme, width);

    return (
        <div style={{...style.line, ...extraStyles}}/>
    );
}

const styles = (theme, width) => ({
    line: {
        width: width || '100%',
        height: 1,
        backgroundColor: theme.colors.darkGrey,
    },
});