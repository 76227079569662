import TranscriptionStateChip from '../TranscriptionStateChip';
import * as React from 'react';
import AvatarPlayButton from "../audio/AvatarPlayButton";
import SummaryStateChip from "../SummaryStateChip";
import NotebookEntryListItem from "./NotebookEntryListItem";

export default function AudioNoteListItem({
                                            note,
                                            owner,
                                            updateAudioNoteTitle,
                                            toggleEntryVisibility,
                                            deleteNote,
                                            tags,
                                            updateEntryTags,
                                            addEntryToChatbot
                                          }) {

  const showBadges = () => {
    return (
      <>
        <TranscriptionStateChip audioNote={note}/>
        <SummaryStateChip summaryContent={note.summaryContent}/>
      </>
    )
  }

  return (
    <NotebookEntryListItem
      deleteNote={deleteNote}
      addEntryToChatbot={addEntryToChatbot}
      tags={tags}
      updateEntryTags={updateEntryTags}
      updateTitle={updateAudioNoteTitle}
      toggleEntryVisibility={toggleEntryVisibility}
      avatarIcon={<AvatarPlayButton audioScr={note.publicUrl} audioOwner={owner}/>}
      linkToNote={owner.seeNotePath()}
      note={note}
      text={note.summaryContent}
      linkShareInfo={{audioEntryId: note.shared_id, owner: owner}}
      showBadges={showBadges}
    />
  )
}