import * as React from "react";
import Box from "@mui/material/Box";

export default function ButtonsContainer({children}) {
  const style = styles();

  return (
    <Box sx={style.buttonsContainer}>
      {children}
    </Box>
  );
}

const styles = () => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  }
});