import LineButton from "./LineButton";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function LineTextButton({onClick, label, disabled, fullWidth = false, light = true, extraStyles = {}}) {
  return (
    <LineButton onClick={onClick} light={light} fullWidth={fullWidth} extraStyles={extraStyles} disabled={disabled}>
      <Typography>
        {label}
      </Typography>
    </LineButton>
  );
}