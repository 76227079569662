import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import NotFoundScreen from '../screens/NotFoundScreen';
import HomeScreen from '../screens/HomeScreen';
import PatientsListScreen from '../screens/PatientsListScreen';
import DoctorLoginScreen from '../screens/logins/DoctorLoginScreen';
import * as React from 'react';
import {observer} from 'mobx-react';
import NewMedicalConsultationWizardScreen
  from '../screens/medicalConsultationWizard/NewMedicalConsultationWizardScreen';
import SeePatientMedicalRecordScreen from '../screens/SeePatientMedicalRecordScreen';
import DoctorsNotesScreen from '../screens/DoctorsNotesScreen';
import SeeAudioNoteScreen from '../screens/SeeAudioNoteScreen';
import SeeTextNoteScreen from '../screens/SeeTextNoteScreen';
import SeeImageFileScreen from '../screens/SeeImageFileScreen';
import SettingsScreen from '../screens/SettingsScreen';
import PatientProfileScreen from '../screens/PatientProfileScreen';
import PatientLoginScreen from '../screens/logins/PatientLoginScreen';
import PatientDashboardScreen from '../screens/PatientDashboardScreen';
import DashboardScreen from '../screens/DoctorDashboardScreen';
import PatientNotesScreen from '../screens/PatientNotesScreen';
import {useApplication} from "../providers/ApplicationProvider";
import PatientHomeScreen from "../screens/PatientHomeScreen";
import SeeSharedEntryScreen from '../screens/SeeSharedEntryScreen';

function AppRouter() {
  const application = useApplication();

  const patientAuthenticatedRoutes = createBrowserRouter([
    {
      path: '*',
      element: <NotFoundScreen/>,
    },
    {
      path: '/',
      element: <PatientDashboardScreen/>,
      children: [
        {
          path: '',
          element: <PatientHomeScreen/>,
        },
        {
          path: 'notes',
          element: <PatientNotesScreen/>,
        },
        {
          path: 'profile',
          element: <PatientProfileScreen/>,
        },
        {
          path: 'notes/image/:entryId',
          element: <SeeImageFileScreen/>,
        },
        {
          path: 'notes/audio_note',
          element: <SeeAudioNoteScreen/>,
        },
        {
          path: 'notes/text_note',
          element: <SeeTextNoteScreen/>,
        },
        {
          path: 'settings',
          element: <SettingsScreen/>,
        },
      ]
    }
  ]);

  const doctorsAuthenticatedRoutes = createBrowserRouter([
    {
      path: '*',
      element: <NotFoundScreen/>,
    },
    {
      path: '/',
      element: <DashboardScreen/>,
      children: [
        {
          path: '',
          element: <HomeScreen/>,
        },
        {
          path: 'patients',
          element: <PatientsListScreen/>,
        },
        {
          path: 'notes',
          element: <DoctorsNotesScreen/>,
        },
        {
          path: 'notes/image/:entryId',
          element: <SeeImageFileScreen/>,
        },
        {
          path: 'notes/audio_note',
          element: <SeeAudioNoteScreen/>,
        },
        {
          path: 'notes/text_note',
          element: <SeeTextNoteScreen/>,
        },
        {
          path: 'patients/:patientId',
          element: <SeePatientMedicalRecordScreen/>,
        },
        {
          path: 'patients/:identificationNumber/text_note',
          element: <SeeTextNoteScreen/>,
        },
        {
          path: 'patients/audio-note',
          element: <SeeAudioNoteScreen/>,
        },
        {
          path: 'patients/:identificationNumber/image/:entryId',
          element: <SeeImageFileScreen/>,
        },
        {
          path: 'patients/:patientId/shared_entry/:entryId',
          element: <SeeSharedEntryScreen/>,
        },
        {
          path: 'patients/new-consultation',
          element: <NewMedicalConsultationWizardScreen/>,
        },
        {
          path: 'settings',
          element: <SettingsScreen/>,
        },
      ],
    }
  ]);

  const unauthenticatedRoutes = createBrowserRouter([
    {
      path: '/login/patient',
      element: <PatientLoginScreen/>,
    },
    {
      path: '/login/doctor',
      element: <DoctorLoginScreen/>,
    },
    {
      path: '*',
      element: <DoctorLoginScreen/>,
    }
  ]);

  const authenticatedRoutes = () => {
    return application.user.isDoctor() ? doctorsAuthenticatedRoutes : patientAuthenticatedRoutes;
  }

  const routes = application.userIsLoggedIn ? authenticatedRoutes() : unauthenticatedRoutes;

  return <RouterProvider router={routes}/>;
}

export default observer(AppRouter);