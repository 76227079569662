export default class Doctor {

  isPatient() {
    return false;
  }

  isDoctor() {
    return true;
  }

  id() {
    return '';
  }

  displayInAudioPlayer() {
    return 'Mi nota'
  }

  seeNotePath() {
    return `/notes/audio_note`;
  }

  path() {
    return `/notes`;
  }

}