import {action, computed, makeObservable, observable} from 'mobx';
import TextField from '../libs/forms/fields/TextField';
import MaiaForm from '../libs/forms/MaiaForm';
import Wizard from '../libs/wizards/Wizard';
import {FormWizardStep} from '../libs/wizards/FormWizardStep';
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import EmailField from '../libs/forms/fields/EmailField';
import DateField from '../libs/forms/fields/DateField';
import EthnicGroupField from '../libs/forms/fields/custom_fields/EthnicGroupField';
import BiologicalSexField from '../libs/forms/fields/custom_fields/BiologicalSexField';
import {riskFactors, symptomsAppearance} from '../app/Constants';
import OptionLabeledField from '../libs/forms/fields/OptionLabeledField';
import MultipleOptionsSectionsField from "../libs/forms/fields/MultipleOptionsSectionsField";

export default class NewMedicalConsultationWizardFeature {
  constructor(application, navigator) {
    this._application = application;
    this._navigator = navigator;
    this._patient = null;
    this._patientForm = this._createPatientForm();
    this._symptomsForm = this._createSymptomsForm();
    this._wizard = this._createWizard();

    makeObservable(this, {
      _patientForm: observable,
      _symptomsForm: observable,
      _wizard: observable,
      _patient: observable,
      _updatePatient: action,
      patientForm: computed,
      wizard: computed,
    })
  }

  _handlerForMedicalConsultationCreation = () => {
    return new ApiResponseHandler({
      handlesSuccess: (response) => {
        const patientId = response.medicalConsultation().patient.shared_id;
        this._navigator(`/patients/${patientId}`);
      }
    });
  }

  _createMedicalConsultation = () => {
    if (this._patient) {
      this._createMedicalConsultationFor(this._patient);
    } else {
      this._createFirstMedicalConsultation();
    }
  }

  _createFirstMedicalConsultation = () => {
    const responseHandler = this._handlerForMedicalConsultationCreation();
    const patient = this.patientForm.values;
    const medicalConsultation = this.symptomsForm.values;
    this._application.apiClient().startFirstMedicalConsultation(patient, medicalConsultation, responseHandler);
  }

  _createMedicalConsultationFor = (patient) => {
    const responseHandler = this._handlerForMedicalConsultationCreation();
    const medicalConsultation = this.symptomsForm.values;
    this._application.apiClient().startMedicalConsultation(patient, medicalConsultation, responseHandler);
  }

  retrievePatientIfExists = () => {
    this._updatePatient(null);
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        this._moveToNextStep()
      },
      handlesSuccess: (response) => {
        this._updatePatient(response.patient());
        this._moveToSymptomsStep();
      }
    });
    const identificationNumber = this.patientForm.getFieldValue('identificationNumber');
    this._application.apiClient().findPatientByIdentificationNumber(identificationNumber, responseHandler);
  }

  _createPatientForm() {
    const fields = {
      "firstName": new TextField(2, 20),
      "lastName": new TextField(2, 20),
      "email": new EmailField(),
      "biologicalSex": new BiologicalSexField(),
      "birthdate": new DateField(),
      "identificationNumber": new TextField(3, 15),
      "ethnicGroup": new EthnicGroupField().asOptional(),
      "familyHistory": new TextField(1, 5000).asOptional(),
      "riskFactors": new MultipleOptionsSectionsField(riskFactors).asOptional(),
    }

    return new MaiaForm(fields);
  }

  _createSymptomsForm() {
    const fields = {
      "symptoms": new TextField(1, 5000),
      "appearedDaysAgo": new OptionLabeledField(symptomsAppearance),
    }

    return new MaiaForm(fields);
  }

  _createWizard() {
    const wizard = new Wizard();
    wizard.addSteps(
      [
        new FormWizardStep(
          {
            formField: this.patientForm.getFieldByName('identificationNumber'),
            loading: () => false,
            onContinue: this.retrievePatientIfExists,
            extraProps: {}
          }
        ),
        new FormWizardStep(
          {
            loading: () => false,
            extraProps: {
              form: this.patientForm,
            }
          }
        ),
        new FormWizardStep(
          {
            loading: () => false,
            onBack: () => this._patient ? this._moveToPatientIdStep() : this._moveToPreviousStep(),
            extraProps: {
              form: this.symptomsForm,
            }
          }
        ),
        new FormWizardStep(
          {
            loading: () => false,
            onBack: () => this._patient ? this.wizard.moveToStep(0) : this.wizard.moveToPreviousStep(),
            extraProps: {
              patientForm: this._patientForm,
            }
          }
        ),
        new FormWizardStep(
          {
            loading: () => false,
            extraProps: {
              patientForm: this._patientForm,
            }
          }
        ),
        new FormWizardStep(
          {
            loading: () => false,
            onContinue: this._createMedicalConsultation,
            extraProps: {
              patientForm: this._patientForm,
              symptomsForm: this._symptomsForm,
            }
          }
        )
      ]
    );
    return wizard;
  }

  _moveToPreviousStep() {
    return this.wizard.moveToPreviousStep();
  }

  _moveToNextStep() {
    this.wizard.moveToNextStep();
  }

  _moveToPatientIdStep() {
    return this.wizard.moveToStep(0);
  }

  _moveToSymptomsStep() {
    this.wizard.moveToStep(2);
  }

  get wizard() {
    return this._wizard;
  }

  get patientForm() {
    return this._patientForm;
  }

  get symptomsForm() {
    return this._symptomsForm;
  }

  set form(newForm) {
    this._patientForm = newForm;
  }

  _updatePatient(patient) {
    this._patient = patient;
    this._patientForm.getFieldByName('riskFactors').updateValue(patient?.risk_factors || []);
    this._patientForm.getFieldByName('familyHistory').updateValue(patient?.family_history || "");
  }

}