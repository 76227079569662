import MaiaCircularProgress from "./MaiaCircularProgress";
import Typography from "@mui/material/Typography";

export default function LoadingContainer({message}) {
  const style = styles();

  return (
    <div style={style.loaderContainer}>
      <MaiaCircularProgress/>
      <Typography>
        {message}
      </Typography>
    </div>
  );
}

const styles = () => ({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center'
  }
});