import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import FormTextField from "./fields/FormTextField";
import FilledButton from "./buttons/FilledButton";
import {observer} from "mobx-react";
import {useTheme} from "@mui/material";
import TextField from "../libs/forms/fields/TextField";

function SearchBar({onSearch, placeholder, searchDisabled=false}) {
  const theme = useTheme();
  const queryField = new TextField(2, 50);
  const style = styles(theme);

  const submitSearch = () => {
    if(queryField.isValid) {
      return onSearch(queryField.value);
    }
  }

  return (
    <Box sx={style.queryBoxContainer}>
      <FormTextField
        field={queryField}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitSearch();
          }
        }}
      />
      <FilledButton onClick={() => submitSearch()} disabled={searchDisabled}>
        <Typography>
          Buscar
        </Typography>
      </FilledButton>
    </Box>
  );
}

export default observer(SearchBar);

const styles = (theme) => ({
  queryBoxContainer: {
    backgroundColor: theme.colors.lightBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '16px',
    gap: '16px',
  },
});