import Typography from '@mui/material/Typography';
import {useApplication} from '../providers/ApplicationProvider';
import {useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import Box from '@mui/material/Box';
import useFeature from '../hooks/useFeature';
import FormTextField from '../components/fields/FormTextField';
import {SettingsFeature} from '../features/SettingsFeature';
import EditableTagChip from "../components/EditableTagChip";

function SettingsScreen() {
  const application = useApplication();
  const theme = useTheme();
  const feature = useFeature(() => new SettingsFeature(application), []);
  const style = styles(theme);

  return feature && (
    <section>
      <Box sx={style.mainContainer}>
        <Typography color={theme.text.primary} variant="h5" fontWeight={'800'}>
          Configuración
        </Typography>
        <Box sx={style.settingBox}>
          <Typography color={theme.text.primary} variant="body1" fontWeight={'800'}>
            Etiquetas
          </Typography>
          <Box sx={style.tagContainer}>
            {feature.tags.map(tag => (
              <EditableTagChip tag={tag}
                               key={tag.name}
                               onEdit={feature.editTagName}
                               onDelete={() => feature.deleteTag(tag)}/>
            ))}
            {feature.tags.length === 0 && (
              <Typography color={theme.text.primary} variant="body1">
                No hay etiquetas
              </Typography>
            )}
          </Box>
          <Box sx={style.newTagForm}>
            <FormTextField
              field={feature._newTagForm.getFieldByName('name')}
              placeholder="Nueva etiqueta"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  feature.createNewTag();
                }
              }}/>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

export default observer(SettingsScreen);

const styles = (theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  settingBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    background: theme.colors.lightBackground,
    padding: '1rem'
  },
  tagContainer: {
    display: 'flex',
    gap: '8px'
  },
  newTagForm: {
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  }
});