export default class ListAdapter {
  constructor(list, adapterClass) {
    this._list = list;
    this._adapterClass = adapterClass;
  }

  adapt() {
    return this._list.map(item => {
      return new this._adapterClass(item).adapt();
    });
  }
}