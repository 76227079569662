import Box from "@mui/material/Box";
import MaiaIconButton from "./buttons/MaiaIconButton";
import CloseIcon from '@mui/icons-material/Close';
import * as React from "react";
import {useState} from "react";
import Typography from "@mui/material/Typography";

export default function EditableTagChip({tag, onDelete, onEdit}) {
  const [editMode, setEditMode] = useState(false);
  const style = styles();

  const renderName = () => {
    return (
      <Typography
        variant="body2"
        color="text.primary"
        style={{zIndex: 2}}
        onClick={(e) => {
          e.preventDefault();
          setEditMode(true)
        }}
      >
        {tag.name}
      </Typography>
    )
  }

  const changeTitleInput = () => {
    return (
      <input style={{background: 'none', border: 'none', fontSize: '1rem', width: '100px'}}
             type={'text'}
             autoFocus={true}
             onBlur={(e) => {
               const newName = e.target.value;
               onEdit(tag, newName);
               setEditMode(false);
             }}
             onKeyDown={(e) => {
               if (e.key === "Enter") {
                 const newName = e.target.value;
                 onEdit(tag, newName);
                 setEditMode(false);
               }
             }}
             defaultValue={tag.name}/>
    )
  }

  return (
    <Box key={tag.id} sx={style.tagContainer}>
      {editMode ? changeTitleInput() : renderName()}
      <MaiaIconButton icon={CloseIcon}
                      title={'Cancel'}
                      iconSize={'1rem'}
                      onClick={() => onDelete(tag)}/>
    </Box>
  )

}

const styles = () => ({
  tagContainer: {
    backgroundColor: '#ffd05c',
    borderRadius: '2px',
    padding: '0.1rem 0.4rem',
    display: 'flex',
    alignItems: 'space-between',
    gap: '0.4rem',
  },
});