import {useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react';
import MaiaIconButton from '../buttons/MaiaIconButton';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/AudioBox.css';
import Box from '@mui/material/Box';
import PlayButton from './PlayButton';
import {useAudioPlayer} from '../../providers/AudioPlayerProvider';
import {useEffect, useRef} from 'react';
import '../../assets/progress.css';
import {Link} from 'react-router-dom';


function VisualAudioPlayer() {
  const theme = useTheme();
  const audioPlayer = useAudioPlayer();
  const audioRef = useRef(null);
  const style = styles(theme);
  const audioScr = audioPlayer.audioSrcPlaying;
  const audioOwner = audioPlayer.audioOwner;

  useEffect(() => {
    const audio = audioRef.current;
    audioPlayer.setAudioElement(audio);

    return () => {
      audioPlayer.removeAudioElement();
    }
  }, [audioPlayer]);

  return <>
    <audio ref={audioRef} src={audioScr} autoPlay/>
    {audioPlayer.isAudioPlaying && (
      <Box sx={style.audioPlayerContainer}>
        <Box sx={style.controls}>
          <PlayButton audioScr={audioScr} audioOwner={audioOwner}
                      size={24} iconColor={theme.colors.button}/>
          <Link to={audioOwner.path()}>
            <Typography color={theme.text.secondary}>
              {audioOwner.displayInAudioPlayer()}
            </Typography>
          </Link>
          <MaiaIconButton icon={CloseIcon} title={"Cerrar"} onClick={audioPlayer.close}/>
        </Box>
        <progress value={audioPlayer.audioProgress} max="100"/>
      </Box>)}
  </>;

}

export default observer(VisualAudioPlayer);

const styles = (theme) => ({
  audioPlayerContainer: {
    background: theme.palette.pink.light,
    position: 'fixed',
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      top: 0,
    },
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(2),
      minWidth: '20vw',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      borderRadius: '5px',
    }
  },
  controls: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    padding: '0.5em 0.7em'
  }
});