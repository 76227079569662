import AudioNoteAdapter from "./AudioNoteAdapter";
import TextNoteAdapter from "./TextNoteAdapter";
import FileAdapter from "./FileAdapter";

export default class NotebookEntryAdapter {
  constructor(entry) {
    this._entryAsJson = entry;
  }

  adapt() {
    if (this._entryAsJson.type === 'audio') {
      return new AudioNoteAdapter(this._entryAsJson).adapt();
    } else if (this._entryAsJson.type === 'text') {
      return new TextNoteAdapter(this._entryAsJson).adapt();
    } else {
      return new FileAdapter(this._entryAsJson).adapt();
    }
  }
}