import React from 'react';
import AudioPlayer from '../app/AudioPlayer';
import { useVoiceAudioRecorder } from './VoiceAudioRecorderProvider';

export const AudioPlayerContext = React.createContext(null);

export const AudioPlayerProvider = ({ children }) => {
  const voiceAudioRecorder = useVoiceAudioRecorder();
  const audioPlayer = new AudioPlayer(voiceAudioRecorder);
  return (
    <AudioPlayerContext.Provider value={audioPlayer}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => React.useContext(AudioPlayerContext);