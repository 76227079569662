import { AudioEntry } from '../../../domain/AudioEntry';

export default class AudioNoteAdapter {
  constructor(audioNote) {
    this._audioNote = audioNote;
  }

  adapt() {
    return new AudioEntry({
      shared_id: this._audioNote.shared_id,
      name: this._audioNote.name,
      title: this._audioNote.title,
      isPublic: this._audioNote.public,
      creationDate: this._audioNote.creation_date,
      publicUrl: this._audioNote.public_url,
      hasFinished: this._audioNote.transcription.finished,
      hasStarted: this._audioNote.transcription.started,
      summaryContent: this._audioNote.summary_content,
      tags: this._audioNote.tags,
    });
  }
}