import {SuccessfulApiResponse} from '@eryxcoop/appyx-comm';

export default class ChatbotAnswerResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        answer: {
          'text': 'Hello',
          'entries_used': [
            {title: 'Un título para probar'}
          ]
        }
      }
    };
  }

  answer() {
    return this.content().answer.text;
  }

  entriesUsed() {
    return this.content().answer.entries_used;
  }
}