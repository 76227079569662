import * as React from "react";
import {useEffect, useRef} from "react";
import Typography from "@mui/material/Typography";
import {readableAudioDuration} from "../libs/utils";
import {observer} from "mobx-react";

function AudioDuration({showLabel = true, publicUrl, styles = {}}) {
  const audioRef = useRef(null);
  const [audioDuration, setAudioDuration] = React.useState(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement) {
      return;
    }

    audioElement.addEventListener('loadedmetadata', onMetadataLoaded);
    audioElement.load();

    return () => {
      audioElement.removeEventListener('loadedmetadata', onMetadataLoaded);
    }
  }, []);

  const onMetadataLoaded = () => {
    setAudioDuration(audioRef.current.duration);
  }

  return (
    <>
      <Typography variant="body1" {...styles}>
        {showLabel ? 'Duración: ' : ''}{readableAudioDuration(audioDuration)}
      </Typography>
      <audio ref={audioRef} src={publicUrl}/>
    </>
  )
}

export default observer(AudioDuration);