import {SuccessfulApiResponse} from '@eryxcoop/appyx-comm';
import NotebookEntryAdapter from "../adapters/NotebookEntryAdapter";

export default class GetRecommendedEntriesResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      "object": {
        "recommendations": [
          {
            "entry": {
              "shared_id": "asdf-123",
              "tags": ["tag1"],
              "creation_date": "2021-01-01",
              "content": "asdf.wav",
              "id": "asdf-123"
            },
            "text_extract": "it was inspired by this text"
          }
        ]
      },
    }
  }

  entriesSearchResult() {
    return this.content().recommendations.map(recommendation => {
      return {
        'entry': new NotebookEntryAdapter(recommendation.entry).adapt(),
        'textExtract': recommendation.text_extract
      }
    })
  }
}