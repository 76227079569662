import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TranscriptionStateChip from './TranscriptionStateChip';
import { parseDate } from '../libs/utils';
import AudioDuration from './AudioDuration';
import AvatarPlayButton from './audio/AvatarPlayButton';
import Transcription from './Transcription';
import * as React from 'react';
import { observer } from 'mobx-react';
import Summary from './Summary';

function AudioNoteView ({ audioNote, audioNoteTranscription, audioOwner, audioNoteTranscriptionDown }) {
  const theme = useTheme();
  const style = styles(theme);

  return <>
    <Box sx={style.audioInfoContainer}>
      <Box sx={style.leftContainer}>
        <TranscriptionStateChip audioNote={audioNote} lightMode={false}/>
        <Typography color={theme.text.primary} variant="body1" fontWeight={'900'}>
          Fecha de creación: {parseDate(audioNote.creationDate)}
        </Typography>
        <AudioDuration publicUrl={audioNote.publicUrl}
                       styles={{ color: theme.text.primary, fontWeight: '900' }}/>
      </Box>
      <AvatarPlayButton audioScr={audioNote.publicUrl}
                        audioOwner={audioOwner}/>
    </Box>
    {
      audioNoteTranscription && <>
        <Transcription audioNoteTranscriptionDown={audioNoteTranscriptionDown}
                       audioNoteTranscription={audioNoteTranscription}/>
        <Summary summary={audioNoteTranscription.summary}/>
      </>
    }
  </>;
}

export default observer(AudioNoteView);

const styles = (theme) => ({
  container: {
    marginBottom: '1rem',
  },
  audioInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.padding.box,
    gap: '1rem',
    backgroundColor: theme.colors.lightBackground,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.8rem',
  },
  transcriptionContainer: {
    gap: '1rem',
    marginBottom: '1rem',
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  }
});