import * as React from 'react';
import AvatarTextNote from '../audio/AvatarTextNote';
import NotebookEntryListItem from "./NotebookEntryListItem";

export default function TextNoteListItem({
                                           note,
                                           updateTextTitle,
                                           tags,
                                           updateEntryTags,
                                           toggleEntryVisibility,
                                           addEntryToChatbot,
                                           deleteNote
                                         }) {

  return (
    <NotebookEntryListItem
      updateTitle={updateTextTitle}
      addEntryToChatbot={addEntryToChatbot}
      deleteNote={deleteNote}
      avatarIcon={<AvatarTextNote/>}
      tags={tags}
      linkToNote={'text_note'}
      updateEntryTags={updateEntryTags}
      toggleEntryVisibility={toggleEntryVisibility}
      note={note}
      text={note.content}
      linkShareInfo={{textNoteId: note.shared_id}}
      showBadges={() => <></>}
    />
  )
}