export const ethnicGroups = [
  {main: "Caucásico o Blanco", secondary: "Mediterránea"},
  {main: "Caucásico o Blanco", secondary: "Este-europea"},
  {main: "Caucásico o Blanco", secondary: "Alpina"},
  {main: "Caucásico o Blanco", secondary: "Dinárica"},
  {main: "Caucásico o Blanco", secondary: "Nórdica"},
  {main: "Caucásico o Blanco", secondary: "Anatolia"},
  {main: "Caucásico o Blanco", secondary: "Turania"},
  {main: "Caucásico o Blanco", secondary: "Sur-oriental"},
  {main: "Caucásico o Blanco", secondary: "Indo-afgana"},
  {main: "Caucásico o Blanco", secondary: "Ainú"},

  {main: "Negro", secondary: "Melanoafricana"},
  {main: "Negro", secondary: "Etíope"},
  {main: "Negro", secondary: "Negrilla (o pigmea)"},
  {main: "Negro", secondary: "Khoisá"},
  {main: "Negro", secondary: "Melanoindia"},
  {main: "Negro", secondary: "Melanesia"},

  {main: "Amarillo", secondary: "Siberiana"},
  {main: "Amarillo", secondary: "Normongólica"},
  {main: "Amarillo", secondary: "Centromongólica"},
  {main: "Amarillo", secondary: "Sudmongólica"},
  {main: "Amarillo", secondary: "Indonesia"},
  {main: "Amarillo", secondary: "Polinesia"},
  {main: "Amarillo", secondary: "Esquimal"},

  {main: "Indígena u Originario", secondary: "Alacalufe (Kawashkar)"},
  {main: "Indígena u Originario", secondary: "Atacameño"},
  {main: "Indígena u Originario", secondary: "Ava Guaraní"},
  {main: "Indígena u Originario", secondary: "Aymara"},
  {main: "Indígena u Originario", secondary: "Bohanes"},
  {main: "Indígena u Originario", secondary: "Chané"},
  {main: "Indígena u Originario", secondary: "Charrúa"},
  {main: "Indígena u Originario", secondary: "Chiquitano"},
  {main: "Indígena u Originario", secondary: "Chorote"},
  {main: "Indígena u Originario", secondary: "Chulupi"},
  {main: "Indígena u Originario", secondary: "Comechingón"},
  {main: "Indígena u Originario", secondary: "Diaguita"},
  {main: "Indígena u Originario", secondary: "Calchaquí"},
  {main: "Indígena u Originario", secondary: "Enxet-Maskoy"},
  {main: "Indígena u Originario", secondary: "Guaicurú"},
  {main: "Indígena u Originario", secondary: "Guaraní"},
  {main: "Indígena u Originario", secondary: "Huarpe"},
  {main: "Indígena u Originario", secondary: "Kolla/Colla/Qolla"},
  {main: "Indígena u Originario", secondary: "Lule"},
  {main: "Indígena u Originario", secondary: "Maimará"},
  {main: "Indígena u Originario", secondary: "Mapuche"},
  {main: "Indígena u Originario", secondary: "Mataguayo"},
  {main: "Indígena u Originario", secondary: "Mbyá Guaraní"},
  {main: "Indígena u Originario", secondary: "Minuanes"},
  {main: "Indígena u Originario", secondary: "Mocoví"},
  {main: "Indígena u Originario", secondary: "Moxeño"},
  {main: "Indígena u Originario", secondary: "Omaguaca"},
  {main: "Indígena u Originario", secondary: "Ona"},
  {main: "Indígena u Originario", secondary: "Pampa"},
  {main: "Indígena u Originario", secondary: "Pilagá"},
  {main: "Indígena u Originario", secondary: "Quechua"},
  {main: "Indígena u Originario", secondary: "Rankulche"},
  {main: "Indígena u Originario", secondary: "Rapa Nui"},
  {main: "Indígena u Originario", secondary: "Sanavirón"},
  {main: "Indígena u Originario", secondary: "Tapiete"},
  {main: "Indígena u Originario", secondary: "Tehuelche"},
  {main: "Indígena u Originario", secondary: "Toba (Qom)"},
  {main: "Indígena u Originario", secondary: "Tonocote"},
  {main: "Indígena u Originario", secondary: "Tupí Guaraní"},
  {main: "Indígena u Originario", secondary: "Uenoas"},
  {main: "Indígena u Originario", secondary: "Vilela"},
  {main: "Indígena u Originario", secondary: "Wichí"},
  {main: "Indígena u Originario", secondary: "Yámana (Yagán)"},
  {main: "Indígena u Originario", secondary: "Yaros"},
  {main: "Indígena u Originario", secondary: "Zamuco"}
];

export const riskFactors = [
  {
    section: "Clínicos", subsection: [
      {name: "Hipertensión arterial", code: "HTA"},
      {name: "Diabetes", code: "DBT"},
      {name: "Tabaquismo", code: "TBQ"},
      {name: "Enfermedad pulmonar obstructiva crónica", code: "EPOC"},
      {name: "Enfermedad coronaria con o sin, colocación de stent", code: "EC"},
      {name: "Accidente cerebro vascular", code: "ACV"},
      {name: "Insuficiencia renal crónica", code: "IRC"},
      {name: "Enfermedades autoinmunes", code: "EA"},
      {
        name: "Inmuno deficiencias",
        code: "ID"
      },
      {name: "Tuberculosis", code: "TBC"},
    ]
  },
  {
    section: "Quirúrgicos", subsection: [
      {name: "Cirugías de vesícula", code: "V"},
      {name: "Apendicitis", code: "APEX"},
      {name: "Hernias", code: "H"},
      {name: "Cesárea", code: "CS"},
      {name: "Trauma", code: "TX"},
      {name: "Neurocirugías", code: "NCx"},
    ]
  },
  {
    section: "Vacunas por endemias", subsection: [
      {name: "Dengue", code: "dengue"},
      {name: "Covid", code: "covid"},
      {name: "Hepatitis B", code: "HB"},
      {name: "Fiebre Amarilla", code: "FA"},
      {name: "Anti vacunas", code: "AV"},
    ]
  }
]

export const biologicalSexOptions = [
  {label: 'Masculino', value: 'MALE'},
  {label: 'Femenino', value: 'FEMALE'},
  {label: 'Intersexual', value: 'INTERSEX'}
]

export const symptomsAppearance = [
  {value: 'AGUDO', label: 'Menos de 7 días'},
  {value: 'SUBAGUDO', label: 'Entre 7 y 14 días'},
  {value: 'CRONICO', label: 'Mayor a 14 días'}
]