import {SuccessfulApiResponse} from "@eryxcoop/appyx-comm";
import TextNoteAdapter from "../adapters/TextNoteAdapter";

export default class GetTextNoteResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        text_note:
          {
            "id": "aas2313ksjd92",
            "creation_date": "2021-01-01",
            "content": 'A very importante note',
          },
      }
    }
  }

  textNote() {
    return new TextNoteAdapter(this.content().text_note).adapt();
  }
}