import {ChatbotBase} from "./ChatbotBase";

export class ChatbotDoctor extends ChatbotBase {

  static newFor(apiClient) {
    const saveConversation = async (title, content, responseHandler) => {
      await apiClient.createTextNote(title, content, responseHandler)
    }
    return new ChatbotDoctor(apiClient, saveConversation);
  }

  _ask(question, responseHandler) {
    this._apiClient.askChatbot(question, responseHandler);
  }
}