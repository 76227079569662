import {action, makeObservable} from 'mobx';
import {toast} from 'react-toastify';

export default class PatientLoginFeature {
  constructor(application, navigate) {
    this._application = application;
    this._navigate = navigate;

    makeObservable(this, {
      loginPatient: action,
    });
  }

  loginPatient = async () => {
    const onError = () => {
      toast.error('Error al iniciar sesión');
    };
    const onSuccess = () => {
      this._navigate('/', {state: {isComingFromLogIn: true}});
    };
    await this._application.logInPatient(onError, onSuccess);
  };

}