import {SuccessfulApiResponse} from "@eryxcoop/appyx-comm";
import TextNoteAdapter from "../adapters/TextNoteAdapter";
import ListAdapter from "../adapters/general/ListAdapter";
import AudioNoteAdapter from "../adapters/AudioNoteAdapter";
import FileAdapter from '../adapters/FileAdapter';

export default class GetPatientMedicalRecordResponse extends SuccessfulApiResponse {
  static defaultResponse() {
    return {
      object: {
        medical_record: {
          patient: {
            "first_name": "John",
            "last_name": "Doe",
            "identification_number": "123458678",
            "has_registered": true,
            "birthdate": "1990-01-01",
            "biological_sex": "MALE"
          },
          notebook: {
            audio_notes: [
              {
                "creation_date": "2021-01-02",
                "public_url": "hello.wav",
                "name": 'note2.wav',
                "has_transcription": true,
                "summary_content": "This is a summary",
              }
            ],
            text_notes: [
              {
                "creation_date": "2021-01-01",
                "content": "asdf.wav",
                "id": "asdf-123"
              },
            ],
            files: [
              {
                "name": "hello.pdf",
                "path": "a_path",
                "public_url": "as.com",
                "creation_date": "4/5/2024",
                "is_an_image": false
              },
            ]
          }
        }
      },
    }
  }

  patient() {
    return this.content().medical_record.patient;
  }

  audioNotes() {
    return new ListAdapter(this.content().medical_record.notebook.audio_notes, AudioNoteAdapter).adapt();
  }

  textNotes() {
    return new ListAdapter(this.content().medical_record.notebook.text_notes, TextNoteAdapter).adapt();
  }

  files() {
    return new ListAdapter(this.content().medical_record.notebook.files, FileAdapter).adapt();
  }
}