import {useTheme} from "@mui/material";
import MaiaIconButton from "./MaiaIconButton";
import * as React from "react";

export default function MaiaFilledIconButton({
                                               icon,
                                               title,
                                               backgroundColor = undefined,
                                               iconColor = undefined,
                                               disabled = false,
                                               iconClassName = undefined,
                                               onClick,
                                               extraStyles={}
                                             }) {
  const theme = useTheme();
  const style = styles(theme, iconColor, backgroundColor);
  iconColor = iconColor || theme.colors.white
  const Icon = icon;

  return (
    <MaiaIconButton
      icon={Icon}
      iconClassName={iconClassName}
      disabled={disabled}
      iconColor={iconColor}
      extraStyles={{...style.button, ...extraStyles}}
      title={title}
      onClick={onClick}
    />
  )
}


const styles = (theme, iconColor, backgroundColor) => ({
  button: {
    backgroundColor: backgroundColor || theme.colors.button,
    color: iconColor || 'white',
    borderRadius: '5px',
    padding: '0.8rem'
  },
});